import { useRef } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import { Languages } from "../../Languages/Languages";

import {
  ActionsBox,
  Avatar,
  ButtonAvatar,
  DashboardHeaderWrapper,
  Email,
  LogoutButton
} from "./Header.styled";

export const DashboardHeader = ({ onOpenMenu }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [, hrefLang] = useLangUrlDefault();
  // **Dispatch
  const { setDefaultUser, setAuthentication, setMobileMenu, resetCabinet } =
    useDispatchedActions();
  // **Redux state
  const { user } = useSelector(getAllUser);

  const letter = user.email.charAt(0).toUpperCase();

  const closeMenu = () => {
    setMobileMenu(false);
  };

  const logout = async () => {
    try {
      const response = await ApiService.logout();

      if (response && response.status !== 200) {
        throw response;
      }

      setDefaultUser();
      resetCabinet();
      setAuthentication(false);

      navigate(`${hrefLang}/`);
      closeMenu();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error: ", err?.response || err?.response || err);
    }
  };

  const openMenu = () => {
    if (window.innerWidth < 992) {
      onOpenMenu("logout");
    } else {
      navigate(`${hrefLang}/dashboard/profile/`);
      ref.current.blur();
    }
  };

  return (
    <DashboardHeaderWrapper>
      <ButtonAvatar ref={ref} onClick={openMenu}>
        <Avatar className="avatar">{letter}</Avatar>
        <Email>{user?.email}</Email>
      </ButtonAvatar>
      <ActionsBox>
        <Languages variant="light" bgHoverColor="white" />
        <LogoutButton
          variant="secondary"
          size="nm"
          type="icon"
          iconLeft="logout"
          onClick={logout}
        />
      </ActionsBox>
    </DashboardHeaderWrapper>
  );
};
