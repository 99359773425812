import { Suspense, useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import { Footer } from "../components/common/Footer/Footer";
import { Header } from "../components/common/Header/Header";
import { BurgerMenu } from "../components/common/Menu/BurgerMenu";
import { ServiceMenu } from "../components/common/Menu/ServiceMenu";
import { Loader } from "../components/ui/Loader/Loader";
import { useMatchedRoute } from "../hooks";

import { AppWrapperStyled } from "./add.styled";

export const NewAppLayout = () => {
  // const { tag } = useMatchedRoute();
  const tag = useMatchedRoute()?.tag;
  const [checkTag, setCheckTag] = useState(null);

  useEffect(() => {
    if (
      !tag ||
      tag === checkTag ||
      (tag?.includes("proxy-site") && checkTag?.includes("proxy-site"))
    ) {
      return;
    }
    setCheckTag(tag);
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  return (
    <AppWrapperStyled bloginner={tag === "bloginner"}>
        <Header />
        <main>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </main>
        <Footer />
        <BurgerMenu />
        <ServiceMenu />
      </AppWrapperStyled>
  );
};
