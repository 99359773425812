export const converToCurrentSocialLink = (cheklink, code) => {
  const link = cheklink.trim();
  if (!link) {return link;}

  if (code === "email") {
    return `mailto:${link}`;
  }

  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  }

  return `https://${link}`;
};
