import { useEffect, useState } from "react";

import axios from "axios";

import { imageTypes } from "../utils/constants/imageTypes";

const DEFAULT_IMAGE = "/img/ui/placeholder.svg";

export const useFavicon = (favicon, faviconContentType, logo) => {
  const [svgBlob, setSvgBlob] = useState(DEFAULT_IMAGE);

  useEffect(() => {
    if (faviconContentType === imageTypes.svg) {
      axios
        .get(favicon, {
          responseType: "blob"
        })
        .then((response) => {
          const binaryData = [];
          binaryData.push(response?.data);
          const blob = URL.createObjectURL(
            // eslint-disable-next-line no-undef
            new Blob(binaryData, { type: "application/text" })
          );
          setSvgBlob(blob);
        });
    }
  }, [faviconContentType, favicon]);

  if (faviconContentType === imageTypes.svg) {
    return svgBlob;
  } else if (favicon) {
    return favicon;
  } else if (logo) {
    return logo;
  } else {return DEFAULT_IMAGE;}
};
