import { useEffect, useId, useRef, useState } from "react";

import PT from "prop-types";

import { useFavicon } from "../../../../hooks";
import { Annotation } from "../../../ui/Annotation/Annotation";
import { Rating } from "../../../ui/Raiting/Rating";
import { ReadReview } from "../../../ui/ReadReview/ReadReview";
import { StyledTooltip } from "../../../ui/Tooltip/Tooltip.styled";

import {
  StyledNameCellImage,
  StyledSiteNameCell,
  StyledSiteNameCellImageBox,
  StyledSiteNameCellInfoBox,
  StyledSiteNameCellSubInfo,
  StyledSiteNameCellTitle
} from "./SiteNameCell.styled";

export const SiteNameCell = ({
  imgUrl,
  favicon,
  faviconContentType,
  name,
  rating = 0,
  showRating = false,
  showAnnotation = false,
  reviewButtonUrl,
  showReviewButton,
  positive = 0,
  negative = 0,
  className
}) => {
  const id = useId();
  const ref = useRef(null);
  const refBox = useRef(null);

  const [needTooltip, setNeedTooltip] = useState(false);
  const image = useFavicon(favicon, faviconContentType, imgUrl);

  useEffect(() => {
    if (ref.current && refBox.current) {
      setNeedTooltip(ref.current.scrollWidth > refBox.current.clientWidth);
    }
  }, [ref]);

  return (
    <StyledSiteNameCell className={className}>
      <StyledSiteNameCellImageBox>
        <StyledNameCellImage
          id="myImage"
          src={image}
          alt={name}
          aria-hidden="true"
          loading="lazy"
        />
      </StyledSiteNameCellImageBox>
      <StyledSiteNameCellInfoBox ref={refBox}>
        {needTooltip ? (
          <StyledTooltip id={id} place="top">
            {name}
          </StyledTooltip>
        ) : null}
        <StyledSiteNameCellTitle ref={ref} data-tooltip-id={id}>
          {name}
        </StyledSiteNameCellTitle>
        {showRating || showAnnotation || showReviewButton ? (
          <StyledSiteNameCellSubInfo>
            {showRating ? (
              <Rating rating={rating} size="sm" background={false} />
            ) : null}
            {showAnnotation ? (
              <Annotation showIcon positive={positive} negative={negative} />
            ) : null}
            {showReviewButton ? <ReadReview linkTo={reviewButtonUrl} /> : null}
          </StyledSiteNameCellSubInfo>
        ) : null}
      </StyledSiteNameCellInfoBox>
    </StyledSiteNameCell>
  );
};
SiteNameCell.propTypes = {
  imgUrl: PT.string,
  name: PT.string,
  rating: PT.number,
  className: PT.string,
  showRating: PT.bool,
  showAnnotation: PT.bool,
  reviewButtonUrl: PT.string,
  positive: PT.number,
  negative: PT.number
};
