import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../../../hooks";

import {
  StyledInfoItemTitle,
  StyledInfoPart,
  StyledListSubInfo,
  StyledListSubInfoItem
} from "./InfoPart.styled";
import { RenderText } from "./RenderText/RenderText";

export const InfoPart = (props) => {
  // **Props
  const [queryLang] = useLangUrlDefault();
  const { review, toggleReview, nestingLevel, isDashboard } = props;
  const { locale = null, translation = {} } = review;

  // **Redux state
  const { t } = useTranslation();

  const isTranslations = Object.keys(translation)?.length > 0;

  // **Local state
  const [isShowOriginal] = useState(false);

  const getReviewData = (data, isAttribute, nameField) => {
    if (isAttribute) {
      return data?.attributes?.[nameField];
    } else {
      return data?.[nameField];
    }
  };

  const getTranslateReviewData = (isAttribute, nameField) => {
    if (
      locale !== queryLang &&
      isTranslations &&
      translation?.[queryLang] &&
      !isShowOriginal
    ) {
      return getReviewData(translation[queryLang], isAttribute, nameField);
    } else {
      return getReviewData(review, isAttribute, nameField);
    }
  };

  const isReviewData = (isAttribute, nameField) =>
    Boolean(getTranslateReviewData(isAttribute, nameField));

  return (
    <StyledInfoPart itemProp="reviewBody">
      {isReviewData(false, "reviewBody") && (
        <RenderText toggleReview={toggleReview} nestingLevel={nestingLevel}>
          {getTranslateReviewData(false, "reviewBody")}
        </RenderText>
      )}
      {isReviewData(false, "commentBody") && (
        <RenderText sub toggleReview={toggleReview} nestingLevel={nestingLevel}>
          {getTranslateReviewData(false, "commentBody")}
        </RenderText>
      )}
      <StyledListSubInfo>
        {isReviewData(true, "advantages") && (
          <StyledListSubInfoItem isDashboard={isDashboard}>
            <StyledInfoItemTitle isDashboard={isDashboard}>
              {t("reviews.content.advantages.title")}:
            </StyledInfoItemTitle>
            <RenderText
              sub
              toggleReview={toggleReview}
              nestingLevel={nestingLevel}
            >
              {getTranslateReviewData(true, "advantages")}
            </RenderText>
          </StyledListSubInfoItem>
        )}
        {isReviewData(true, "disadvantages") && (
          <StyledListSubInfoItem isDashboard={isDashboard}>
            <StyledInfoItemTitle isDashboard={isDashboard}>
              {t("reviews.content.disadvantages.title")}:
            </StyledInfoItemTitle>
            <RenderText
              sub={true}
              toggleReview={toggleReview}
              nestingLevel={nestingLevel}
            >
              {getTranslateReviewData(true, "disadvantages")}
            </RenderText>
          </StyledListSubInfoItem>
        )}
      </StyledListSubInfo>
    </StyledInfoPart>
  );
};
