import { useMemo, useState } from "react";

import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { Export } from "../../../../../components/common/Export/Export";
import {
  StyledNoData,
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../../../../../components/common/Table/ToolTable.styled";
import { Title } from "../../../../../components/ui/Title/Title";
import { handleScrollBottomTable } from "../../../../../utils/helpers/resultToolTable.helper";

import { StyledTableWrapper, StyledTitleWrapper } from "./PortsTable.styled";

export const PortsTable = ({ data = [], isLoading = false }) => {
  const { t } = useTranslation();

  const [scrollBottomTable, setScrollBottomTable] = useState(false);

  const exportData = useMemo(
    () =>
      data
        ?.map((item) => `<div>${item.port}:${item.protocol || "N/A"}</div>`)
        .join(" "),
    [data]
  );

  const txtData = useMemo(
    () => data?.map((item) => `${item.port}:${item.protocol || "N/A"}`),
    [data]
  );

  return (
    <div>
      <StyledTitleWrapper>
        <Title tag="h2">{t("portScanner.portChecked.title")}</Title>
        <Export
          tooltip={t("portScanner.portChecked.tooltip")}
          id="portChecked"
          txtData={txtData || []}
          exportData={exportData || []}
        />
      </StyledTitleWrapper>
      <StyledTableWrapper scrollBottom={!data?.length || scrollBottomTable}>
        <StyledTable paddingMobile="16px">
          <TableWrapper>
            <TableHeadRow>
              <TableHeadCol
                width="88px"
                desktopWidth="8%"
                justifyContent="center"
              >
                №
              </TableHeadCol>
              <TableHeadCol width="265px" desktopWidth="25%">
                {t("portScanner.portChecked.port")}
              </TableHeadCol>
              <TableHeadCol width="180px" desktopWidth="17%">
                {t("portScanner.portChecked.protocol")}
              </TableHeadCol>
              <TableHeadCol width="570px" desktopWidth="50%">
                {t("portScanner.portChecked.description")}
              </TableHeadCol>
            </TableHeadRow>
            <TableBody
              maxHeight="434px"
              onScroll={(e) => handleScrollBottomTable(e, setScrollBottomTable)}
            >
              {!isLoading && (!data || data?.length === 0) ? (
                <StyledNoData>
                  {t("proxyPage.content.table.noDataComponent")}
                </StyledNoData>
              ) : (
                data?.map((item, index) => (
                  <TableRow key={index} height="48px">
                    <TableCol
                      width="88px"
                      desktopWidth="8%"
                      justifyContent="center"
                    >
                      <TableText justifyContent="center">
                        {index < 9 ? `0${index + 1}.` : `${index + 1}.`}
                      </TableText>
                    </TableCol>
                    <TableCol width="265px" desktopWidth="25%">
                      <TableText>{item?.port}</TableText>
                    </TableCol>
                    <TableCol width="180px" desktopWidth="17%">
                      <TableText>{item?.protocol || "N/A"}</TableText>
                    </TableCol>
                    <TableCol width="570px" desktopWidth="50%">
                      <TableText>{item?.description}</TableText>
                    </TableCol>
                  </TableRow>
                ))
              )}
            </TableBody>
          </TableWrapper>
        </StyledTable>
      </StyledTableWrapper>
    </div>
  );
};

PortsTable.propTypes = {
  data: PT.arrayOf(PT.shape({})),
  isLoading: PT.bool
};
