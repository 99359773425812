import { useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthNavigate } from "../../../components/common/AuthNavigate/AuthNavigate";
import { FormCheckbox } from "../../../components/forms/FormCheckbox/FormCheckbox";
import { FormInput } from "../../../components/forms/FormInput/FormInput";
import { useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { RegistrationSchema } from "../../../utils/validation";
import {
  StyledAgreeButton,
  StyledAgreeLabel,
  StyledAuthForm,
  StyledAuthFormComponentBox,
  StyledAuthTitle,
  StyledButtonSubmit
} from "../AuthPages.styled";

const RegistrationPage = () => {
  // **Ref
  const captchaRef = useRef(null);

  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const navigate = useNavigate();

  // **Redux state
  const { t } = useTranslation();
  // const { pageContent, captchaConfig } = useSelector(getAllContent);
  const { captchaConfig } = useSelector(getAllContent);

  // **Local state
  const [isLoading, setIsLoading] = useState(false);
  const [, setTokenCaptcha] = useState(null);
  // const metaData = useMetaData();

  const { enable, clientKey, headerName } = captchaConfig.data;

  // Form
  const methods = useForm({
    resolver: yupResolver(
      RegistrationSchema(t("forms", { returnObjects: true }))
    )
  });

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
      // const { response: token } = await captchaRef.current.execute({
      //   async: true
      // });
      // header = { [headerName]: token };
    }
    try {
      if (data.password !== data.passwordRepeat) {
        toast.error(t("forms.password.rules.mismatch"));
        return;
      }

      setIsLoading(true);

      const response = await ApiService.registerNewUser(
        data.email.toLowerCase(),
        data.password,
        queryLang,
        header
      );

      if (response && response.status !== 200) {
        if (response.request.status === 540) {
          // eslint-disable-next-line no-throw-literal
          toast.clearWaitingQueue();

          throw {
            response,
            message: t(`notifications.authError.${response.request.status}`)
          };
        }

        if (response.request.status === 545) {
          throw {
            response,
            message: t(`notifications.authError.${response.request.status}`)
          };
        }

        if (response.request.status === 400) {
          throw {
            response,
            message: t("notifications.invalidToken")
          };
        }

        throw response;
      }
      navigate(`${hrefLang}/registration/success`);
      // navigate(`${hrefLang}/login`);
    } catch (err) {
      toast.error(err?.message || t("notifications.apiError"));
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  const handleOpenPolicy = () => null;

  return (
    <>
      <AuthNavigate to="login" />
      <StyledAuthTitle noSub>{t("authPages.register.title")}</StyledAuthTitle>
      <FormProvider {...methods}>
        <StyledAuthForm onSubmit={methods.handleSubmit(onSubmit)} noValidate data-testid="regForm">
          <FormInput
            name="email"
            type="email"
            placeholder={t("forms.email.label")}
            label={t("forms.email.label")}
            showError
            dataTestId="regForm_inputEmail"
          />
          <FormInput
            name="password"
            type="password"
            placeholder={t("forms.password.placeholder")}
            label={t("forms.password.placeholder")}
            showError
            dataTestId="regForm_inputPassword"
            dataTestIdShowPassword="regForm_buttonShowPassword"
          />
          <FormInput
            name="passwordRepeat"
            type="password"
            placeholder={t("forms.password.placeholderRepeat")}
            label={t("forms.password.placeholderRepeat")}
            showError
            dataTestId="regForm_inputPasswordRepeat"
            dataTestIdShowPassword="regForm_buttonShowPasswordRepeat"
          />
          <StyledAuthFormComponentBox>
            <FormCheckbox
              name="agree"
              showError
              label={
                <StyledAgreeLabel>
                  {`${t("authPages.agree.with")} `}
                  <StyledAgreeButton onClick={handleOpenPolicy} type="link" dataTestId="regForm_buttonOffer">
                    {t("authPages.agree.offer")}
                  </StyledAgreeButton>
                  {` ${t("authPages.agree.and")} `}
                  <StyledAgreeButton onClick={handleOpenPolicy} type="link" dataTestId="regForm_buttonPrivacyPolicy">
                    {`  ${t("authPages.agree.privacy")}`}
                  </StyledAgreeButton>
                  .
                </StyledAgreeLabel>
              }
              noActionLabel
              dataTestId="regForm_checkboxAgree"
            />
          </StyledAuthFormComponentBox>
          {enable && clientKey && (
            <HCaptcha
              ref={captchaRef}
              sitekey={clientKey}
              onVerify={setTokenCaptcha}
              onExpire={() => setTokenCaptcha(null)}
              onError={(err) => toast.error(err?.message)}
              size="invisible"
            />
          )}
          <StyledButtonSubmit
            type="submit"
            fullWidth
            disabled={isLoading}
            loading={isLoading}
            dataTestId="regForm_buttonSubmit"
          >
            {t("authPages.register.btn")}
          </StyledButtonSubmit>
        </StyledAuthForm>
      </FormProvider>
    </>
  );
};

export default RegistrationPage;

RegistrationPage.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post("/api/page/front/content", {
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
