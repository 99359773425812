import { useState } from "react";

import { useTranslation } from "react-i18next";

import { StyledLoading } from "../../../../../components/ui/Button/Button.styled";
import { handleScrollBottomTable } from "../../../../../utils/helpers/resultToolTable.helper";

import {
  ResultTableWrapper,
  StyledResultTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText
} from "./ResultTable.styled";

export const ResultsTable = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const [scrollBottomTable, setScrollBottomTable] = useState(false);

  return (
    <ResultTableWrapper scrollBottom={scrollBottomTable}>
      <StyledResultTable>
        <TableHeadRow>
          <TableHeadCol>{t("evercookieTest.table.name")}</TableHeadCol>
          <TableHeadCol>{t("evercookieTest.table.value")}</TableHeadCol>
        </TableHeadRow>
        <TableBody
          onScroll={(e) => handleScrollBottomTable(e, setScrollBottomTable)}
        >
          {data?.map((item) => (
            <TableRow key={item.name}>
              <TableCol>
                <TableText>{item?.name}</TableText>
              </TableCol>
              <TableCol>
                <TableText>
                  {isLoading ? <StyledLoading name="loading" /> : item?.value}
                </TableText>
              </TableCol>
            </TableRow>
          ))}
        </TableBody>
      </StyledResultTable>
    </ResultTableWrapper>
  );
};
