import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const fetchSeoBlocks = createAsyncThunk(
  "seoblock/fetchSeoBlocks",
  async ({ keys, languageCode, params }, { rejectWithValue }) => {
    try {
      const response = await ApiService.fetchSeoBlocks(keys, languageCode, params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setSeoBlock = createAction("seoblock/setSeoBlock");
