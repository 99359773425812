import { useTranslation } from "react-i18next";

import { FormCheckbox } from "../../../../../components/forms/FormCheckbox/FormCheckbox";
import { FormDate } from "../../../../../components/forms/FormDate/FormDate";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import {
  StyledBox,
  StyledCheckboxes,
  StyledDateBox,
  StyledLabel
} from "./BlockPeriod.styled";

export const BlockPeriod = ({ eternal }) => {
  const { t } = useTranslation();

  return (
    <FormBlock
      title={t("dashboard.promocodes.addEdit.blocks.period.title")}
      subtitle={t("dashboard.promocodes.addEdit.blocks.period.subtitle")}
    >
      <StyledLabel>
        {t("dashboard.promocodes.addEdit.blocks.period.title")}
      </StyledLabel>
      <StyledBox>
        <StyledCheckboxes>
          <FormCheckbox
            name="eternal"
            type="radio"
            iconType="checkbox"
            label={t("promocodes.eternal.label")}
            radioValue="eternal"
          />
          <FormCheckbox
            name="eternal"
            label={t("dashboard.promocodes.addEdit.blocks.period.date")}
            type="radio"
            iconType="checkbox"
            radioValue="date"
          />
        </StyledCheckboxes>

        {eternal !== "eternal" && (
          <StyledDateBox>
            <FormDate name="dateRange" showError />
          </StyledDateBox>
        )}
      </StyledBox>
    </FormBlock>
  );
};
