import styled from "@emotion/styled";

import { Title } from "../../../../../components/ui/Title/Title";

export const StyledSection = styled.div`
  padding-top: 64px;
  background-color: ${({ theme }) => theme.colors["background-color"]};
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  & .port-scanner-slide {
    display: inline-flex;
    height: auto;
  }
`;

export const StyledTitle = styled(Title)`
  max-width: 530px;
`;
