import { PaginationStyled, RestyledIcon } from "./ReactPagination.styled";

export const ReactPagination = ({
  currentPage,
  rowsPerPage,
  rowCount,
  marginPagesDisplayed,
  pageRangeDisplayed,
  onChangePage
}) => (
  <PaginationStyled
    pageClassName="page"
    forcePage={rowCount === 0 ? -1 : currentPage - 1}
    previousLabel={<RestyledIcon name="arrowDown" rotate={90} />}
    nextLabel={<RestyledIcon name="arrowDown" rotate={-90} />}
    breakLabel="..."
    pageCount={Math.ceil(rowCount / rowsPerPage)}
    marginPagesDisplayed={marginPagesDisplayed}
    pageRangeDisplayed={pageRangeDisplayed}
    onPageChange={(data) => {
      onChangePage(data.selected + 1);
    }}
  />
);
