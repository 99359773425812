import { Suspense, useState } from "react";

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { DashboardHeader } from "../../components/common/Dashboard/Header/Header";
import { DashboardMenu } from "../../components/common/Dashboard/Menu/Menu";
import { Loader } from "../../components/ui/Loader/Loader";
import { useLangUrlDefault } from "../../hooks";
import { getAllUser } from "../../store/reducers/UserReducer/User.selectors";

import {
  DashboardLayoutContent,
  DashboardLayoutStyled,
  SpaceContent,
  StyledLogo
} from "./DashboardLayout.styled";

export const DashboardLayout = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  const [menuOpenType, setMenuOpenType] = useState("default");

  const { isAuthfetched, isUserAuthenticated } = useSelector(getAllUser);

  if (!isAuthfetched) {
    return <Loader type="absolute" />;
  }

  if (!isUserAuthenticated && isAuthfetched) {
    return <Navigate to={`${hrefLang}/login/`} replace />;
  }

  return (
    <DashboardLayoutStyled>
      <DashboardMenu type={menuOpenType} setType={setMenuOpenType} />
      <DashboardLayoutContent>
        <DashboardHeader onOpenMenu={setMenuOpenType} />
        <StyledLogo color="black" />
        <SpaceContent>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </SpaceContent>
      </DashboardLayoutContent>
    </DashboardLayoutStyled>
  );
};
