import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Swiper,
  SwiperSlide
} from "../../../../components/ui/Swiper/Swiper.styled";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import {
  StyledAlternativeTitle,
  StyledAlternativeWrapper,
  StyledServiceDesktopBox,
  StyledServiceDesktopList,
  StyledServiceMobileBox
} from "./Alternative.styled";
import { ServiceItem } from "./ServiceItem/ServiceItem";

export const Alternative = () => {
  const { t } = useTranslation();

  // **Redux state
  const {
    site,
    alternative: { data, isDataLoaded }
  } = useSelector(getAllProxySite);

  const currentData =
    isDataLoaded && data?.length
      ? data?.filter((item) => item.id !== site?.data?.id)?.slice(0, 5)
      : [];

  return (
    <StyledAlternativeWrapper>
      <StyledAlternativeTitle>
        {t("proxySitePage.content.chacracteristics.alternative.title")}
      </StyledAlternativeTitle>

      <StyledServiceMobileBox>
        <Swiper
          direction="horizontal"
          wrapperTag="ul"
          breakpoints={{
            320: {
              slidesPerView: 1.05,
              spaceBetween: 12
            },

            480: {
              slidesPerView: 1.1,
              spaceBetween: 12
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 12
            }
          }}
        >
          {isDataLoaded ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {currentData?.length
                ? currentData.map((item, index) => (
                    <SwiperSlide
                      key={`${item.id}_item_${index}`}
                      style={{ height: "auto" }}
                      tag="li"
                    >
                      <ServiceItem data={item} />
                    </SwiperSlide>
                  ))
                : null}
            </>
          ) : (
            <>
              {[...Array(4)].map((_, index) => (
                <SwiperSlide
                  key={`skeleton_${index}`}
                  style={{ height: "auto" }}
                  tag="li"
                >
                  <ServiceItem loading />
                </SwiperSlide>
              ))}
            </>
          )}
        </Swiper>
      </StyledServiceMobileBox>

      <StyledServiceDesktopBox>
        {isDataLoaded ? (
          <StyledServiceDesktopList>
            {currentData?.length
              ? currentData.map((item, index) => (
                  <li key={`${item.id}_item__${index}`}>
                    <ServiceItem data={item} />
                  </li>
                ))
              : null}
          </StyledServiceDesktopList>
        ) : (
          <StyledServiceDesktopList>
            {[...Array(4)].map((_, index) => (
              <li key={`skeleton_${index}`}>
                <ServiceItem loading />
              </li>
            ))}
          </StyledServiceDesktopList>
        )}
      </StyledServiceDesktopBox>
    </StyledAlternativeWrapper>
  );
};
