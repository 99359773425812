import { useEffect, useState } from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatchedActions } from "../../../hooks";
import { ApiService } from "../../../services";
import { getAllCabinet } from "../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { StyledProfileCard } from "../Profile/Profile.styled";

import {
  StyledBreadcrumbs,
  StyledTitle,
  StyledTitleCard
} from "./AddEditPromocode.styled";
import { Form } from "./Form/Form";

const AddEditPromocode = () => {
  // **Props
  //   const { title, isEdit } = props;
  const { id } = useParams();
  const { t } = useTranslation();

  // **Redux state
  const { allProxies } = useSelector(getAllCabinet);

  // **Local state
  const [defaultValues, setDefaultValues] = useState({
    startDate: new Date(moment.utc().format()),
    endDate: new Date(moment.utc().add(7, "d").format()),
    eternal: "eternal",
    isActive: true
  });

  // Dispatch
  const { getAllCabinetSites } = useDispatchedActions();

  useEffect(() => {
    if (!allProxies.isDataLoaded) {
      getAllCabinetSites();
      return;
    }

    if (id) {
      const fetchPromocodeById = async () => {
        try {
          const response = await ApiService.getPromocodeByIdForPersonalAcc(id);

          if (response && response.status !== 200) {
            throw response;
          }

          setDefaultValues({
            ...response.data,
            startDate: new Date(moment(response.data.startDate)),
            endDate: response.data.endDate
              ? new Date(moment(response.data.endDate))
              : new Date(moment.utc().add(7, "d").format()),
            eternal: response.data.eternal ? "eternal" : "date"
          });
        } catch (err) {
          toast.error(t("notifications.apiError"));
        }
      };
      fetchPromocodeById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProxies.isDataLoaded]);

  // Handling error
  useEffect(() => {
    if (allProxies.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProxies.isError]);
  return (
    <>
      <StyledBreadcrumbs
        items={[
          {
            title: t("dashboard.promocodes.table.title"),
            route: "dashboard/promocodes/"
          },
          {
            title: t(`dashboard.promocodes.addEdit.title${id ? "Edit" : "Add"}`)
          }
        ]}
      />
      <StyledTitle size="sm">
        {t(`dashboard.promocodes.addEdit.title${id ? "Edit" : "Add"}`)}
      </StyledTitle>
      <StyledProfileCard>
        <StyledTitleCard size="sm">
          {t(`dashboard.promocodes.addEdit.title${id ? "Edit" : "Add"}`)}
        </StyledTitleCard>

        <Form isEdit={Boolean(id)} defaultValues={defaultValues} />
      </StyledProfileCard>
    </>
  );
};
export default AddEditPromocode;
