import { useSelector } from "react-redux";

import { RecentReviews } from "../../../../components/common/RecentReviews/RecentReviews";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";

import { UserChoiceTable } from "./UserChoiceTable/UserChoiceTable";
import { StyledUserChoiceContainer } from "./UserChoise.styled";

export const UserChoice = () => {
  // **Redux state
  const { reviews } = useSelector(getAllContent);
  return (
    <section>
      <StyledUserChoiceContainer>
        <UserChoiceTable />
        <RecentReviews
          itemHovered
          isMainPage
          reviews={reviews}
          isDataLoaded={reviews.isDataLoaded}
        />
      </StyledUserChoiceContainer>
    </section>
  );
};
