import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const RestyledMainSection = styled(Main)`
  // padding-bottom: 80px;
  // @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
  //   padding-bottom: 180px;
  //   min-height: 530px;
  // }

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    // min-height: 580px;

    & .main-description {
      width: 100%;
    }
  }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 105px;
      padding-bottom: 307px;
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 47px;
      right: 40px;
      width: 340px;
      height: 226px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: 30px;
      width: 424px;
      height: 283px;
    }

    @media (min-width: 1350px) {
      right: -60px;
    }

    &::before {
      width: 153px;
      height: 153px;
      bottom: 15%;
    }
  }
`;

export const StyledFAQSection = styled(FAQ)`
  img {
    width: 329px;
  }
`;
