import { SkeletonTheme } from "react-loading-skeleton";

import { StyledSkeleton } from "../components/SkeletonTableComponents.styled";

import {
  StyledReviewCard,
  StyledReviewFooter,
  StyledReviewFooterBox,
  StyledReviewHeader,
  StyledReviewHeaderBox,
  StyledReviewSubInfo,
  StyledReviewSubInfoContent
} from "./SkeletonReviews.styled";

export const SkeletonReviews = ({ cells }) => (
  <SkeletonTheme inline>
    {Array(cells)
      .fill(0)
      .map((_, i) => (
        <StyledReviewCard key={`item-skeleton-${i}`}>
          <StyledReviewHeader>
            <StyledReviewHeaderBox>
              <StyledSkeleton width={150} height={18} />
              <StyledSkeleton width={110} height={20} />
            </StyledReviewHeaderBox>
            <StyledReviewHeaderBox align="flex-end">
              <StyledSkeleton width={110} height={16} />
              <StyledSkeleton width={100} height={16} />
            </StyledReviewHeaderBox>
          </StyledReviewHeader>
          <StyledSkeleton height={14} count={3} />
          <StyledReviewSubInfo>
            <StyledSkeleton width={100} height={14} />
            <StyledReviewSubInfoContent>
              <StyledSkeleton height={14} count={2} />
            </StyledReviewSubInfoContent>
          </StyledReviewSubInfo>
          <StyledReviewSubInfo>
            <StyledSkeleton width={100} height={14} />
            <StyledReviewSubInfoContent>
              <StyledSkeleton height={14} count={2} />
            </StyledReviewSubInfoContent>
          </StyledReviewSubInfo>
          <StyledReviewFooter>
            <StyledReviewFooterBox>
              <StyledSkeleton width={75} height={14} />
              <StyledSkeleton width={75} height={14} />
            </StyledReviewFooterBox>
            <StyledReviewFooterBox>
              <StyledSkeleton width={24} height={24} />
              <StyledSkeleton width={24} height={24} />
              <StyledSkeleton width={24} height={24} />
            </StyledReviewFooterBox>
          </StyledReviewFooter>
        </StyledReviewCard>
      ))}
  </SkeletonTheme>
);
