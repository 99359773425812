/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../../../components/ui/Button/Button";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { ApiService } from "../../../../services";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../store/reducers/UserReducer/User.selectors";
import {
  ReviewSchema,
  ReviewSchemaWithAuth
} from "../../../../utils/validation";
import { FormInput } from "../../../forms/FormInput/FormInput";

import {
  FormRow,
  LabelStyled,
  RestyledModal,
  StyledForm
} from "./LeaveReviewModal.styled";
import { PersonalInfo } from "./PersonalInfo/PersonalInfo";
import { Ratings } from "./Ratings/Ratings";
import { Success } from "./Success/Success";

export const LeaveReviewModal = (props) => {
  // **Props
  const {
    visible,
    captchaRef,
    setTokenCaptcha,
    isEdit,
    reviewItem,
    cancelHandler,
    updateHandler
  } = props;
  // **Props
  const { alias } = useParams();
  const [queryLang] = useLangUrlDefault();

  const { t } = useTranslation();

  const { captchaConfig } = useSelector(getAllContent);
  const { isUserAuthenticated } = useSelector(getAllUser);
  const { site, reviews } = useSelector(getAllProxySite);

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enable, headerName } = captchaConfig.data;

  // **Dispatch
  const { getLastSiteReviews, getProxySiteReviews } = useDispatchedActions();

  // Form
  const methods = useForm({
    resolver: yupResolver(
      isUserAuthenticated
        ? ReviewSchema("forms", t)
        : ReviewSchemaWithAuth("forms", t)
    )
  });

  const titleReviewModal = isEdit
    ? t("modals.review.titleEdit")
    : t("modals.review.titleAdd");

  const hendlerCloseModal = () => {
    setSuccess(false);
    cancelHandler(false);
    methods.reset();
  };

  const onSubmit = async (data) => {
    let header = {};

    if (enable) {
      try {
        const { response: token } = await captchaRef.current.execute({
          async: true
        });
        header = { [headerName]: token };
      } catch (ignore) {
        return;
      }
    }

    try {
      setIsLoading(true);

      const { name, email, ...rest } = data;
      const user = !isUserAuthenticated
        ? {
            name,
            email: email.toLowerCase(),
            locale: queryLang
          }
        : null;

      const params = {
        attributes: {
          advantages: rest.advantages || "",
          disadvantages: rest.disadvantages || ""
        },
        ratings: {
          SPEED: rest?.speedRating || 0,
          PRICE: rest?.priceRating || 0,
          RELIABILITY: rest?.reliabilityRating || 0,
          SUPPORT: rest?.supportRating || 0
        }
      };

      if (isEdit) {
        params.id = reviewItem.id;
        params.review = rest.reviewBody;
      } else {
        params.siteId = site.data?.id;
        params.user = user;
        params.reviewBody = rest.reviewBody;
      }

      const response = !isEdit
        ? await ApiService.createSiteReview(params, header)
        : await ApiService.updateSiteReviewNew(params, header);

      if (response && response.status !== 200) {
        throw response;
      }

      // Reset form (except rating)
      methods.reset();

      // Show success message
      if (isEdit) {
        toast.success(t("notifications.modals.reviewEdit"));
      } else {
        setSuccess(true);
      }

      // Get new last reviews
      getLastSiteReviews();
      if (!isEdit) {
        getProxySiteReviews({
          ...reviews.fetchedParams,
          siteId: alias
        });
      } else {
        updateHandler();
      }
    } catch (err) {
      if (err.response.status === 540) {
        methods.reset();
        props.cancelHandler(false);
        toast.error(t("notifications.modals.reviewAlreadySend"));
      } else {
        toast.error(t("notifications.apiError"));
      }
    } finally {
      if (enable) {
        captchaRef.current.resetCaptcha();
        setTokenCaptcha(null);
      }

      setIsLoading(false);
    }
  };

  const onChangeDelUrlOrChar = (evt) => {
    methods.setValue(
      evt.target.name,
      evt.target.value.replace(
        /[^a-zа-яёіїє0-9!,.?()\s]|\b(https?:\/\/\S*\b)/gi,
        ""
      )
    );
  };

  useEffect(() => {
    if (isEdit && reviewItem && methods) {
      const newFormData = {
        advantages: reviewItem?.attributes?.advantages || "",
        disadvantages: reviewItem?.attributes?.disadvantages || "",
        reviewBody: reviewItem?.reviewBody,
        speedRating: reviewItem?.ratings?.SPEED,
        priceRating: reviewItem?.ratings?.PRICE,
        reliabilityRating: reviewItem?.ratings?.RELIABILITY,
        supportRating: reviewItem?.ratings?.SUPPORT
      };

      methods.reset(newFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, reviewItem]);

  return (
    <RestyledModal
      open={!!visible}
      setOpen={hendlerCloseModal}
      title={!success ? titleReviewModal : null}
      minWidth={320}
      maxWidth={success ? 545 : 656}
      mobilePosition="bottom"
      // className={`${styles.modal} ${success ? styles.modal_success : ""}`}
      // classNameBody={styles.modal_body}
    >
      {success ? (
        <Success />
      ) : (
        <FormProvider {...methods}>
          <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Ratings />
            <FormRow>
              <FormInput
                label={
                  <LabelStyled>
                    {t("modals.review.form.advantages")}
                  </LabelStyled>
                }
                name="advantages"
                type="textarea"
                showError
                placeholder={t("modals.review.form.placeholder")}
                onChange={onChangeDelUrlOrChar}
              />
              <FormInput
                label={
                  <LabelStyled>
                    {t("modals.review.form.disadvantages")}
                  </LabelStyled>
                }
                name="disadvantages"
                type="textarea"
                showError
                placeholder={t("modals.review.form.placeholder")}
                onChange={onChangeDelUrlOrChar}
              />
            </FormRow>
            <FormInput
              label={
                <LabelStyled>{t("modals.review.form.review")}</LabelStyled>
              }
              name="reviewBody"
              type="textarea"
              showError
              placeholder={t("modals.review.form.placeholder")}
              className="review_text"
              showMaxLenght={100}
              onChange={onChangeDelUrlOrChar}
            />
            {!isUserAuthenticated && <PersonalInfo />}

            <Button
              disabled={isLoading}
              type="submit"
              isLoading={isLoading}
              fullWidth
            >
              {titleReviewModal}
            </Button>
          </StyledForm>
        </FormProvider>
      )}
    </RestyledModal>
  );
};
