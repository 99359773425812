import PT from "prop-types";
import { useTranslation } from "react-i18next";

import {
  StyledContent,
  StyledDescription, // StyledImageWrapper,
  StyledStep,
  StyledTitle,
  StyledWrapper
} from "./PortScannerStep.styled";

export const PortScannerStep = ({ title, description, index }) => {
  const { t } = useTranslation();
  return (
    <StyledWrapper>
      <StyledContent>
        <StyledTitle tag="h2">{title}</StyledTitle>
        <StyledStep>{`${t("ui.step")} ${index}`}</StyledStep>
        <StyledDescription>{description}</StyledDescription>
      </StyledContent>
      {/* <StyledImageWrapper mobile={index === 1}>
      <img
        src={image}
        loading="lazy"
        alt={`${title} - ${index}`}
        aria-hidden="true"
      />
    </StyledImageWrapper> */}
    </StyledWrapper>
  );
};

PortScannerStep.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};
