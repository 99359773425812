import { useEffect } from "react";

import { useFieldArray, useFormContext } from "react-hook-form";

import { FormInput } from "../FormInput/FormInput";

import {
  AddNewButtonStyled,
  FormListContainer,
  FormListItem,
  FormListStyled,
  RemoveButtonStyled
} from "./FormList.styled";

export const FormList = ({ name, placeholder, addButtonText, className }) => {
  // ** Hooks
  const { control, watch } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({ control, name });
  const handlerAddNew = () => append("");
  const values = watch(name);
  useEffect(() => {
    if (values?.length > 0 && fields?.length === 0) {
      replace(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <FormListContainer className={className}>
      <FormListStyled>
        {fields.map((item, index) => (
          <FormListItem key={item.id}>
            <FormInput
              name={`${name}.${index}`}
              type="text"
              rules={{ required: true }}
              placeholder={placeholder}
            />
            <RemoveButtonStyled
              variant="secondary"
              type="icon"
              iconLeft="minus"
              onClick={() => remove(index)}
            />
          </FormListItem>
        ))}
      </FormListStyled>
      <AddNewButtonStyled
        fullWidth
        variant="secondary"
        iconLeft="plus"
        onClick={handlerAddNew}
      >
        {addButtonText || "Add new"}
      </AddNewButtonStyled>
    </FormListContainer>
  );
};
