import camelCase from "lodash.camelcase";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";
import { ACTIVE_STATUS } from "../../../../utils/constants/activeStatus.constant";
import { proxyServicesList } from "../../../../utils/helpers/serviceMenu.helpers";
import { Chip } from "../../../ui/Chip/Chip";
import { Countries } from "../Countries/Countries";
import {
  StyledList,
  StyledListDescription,
  StyledListItem,
  StyledListLink,
  StyledListTitle
} from "../Navigation/Navigation.styled";
import { NavigationItem } from "../Navigation/NavigationItem";
import { StyledLocationsList } from "../ProxiesNavigation/ProxiesNavigation.styled";

import {
  LocationsNavigationItem,
  StyledButton,
  StyledIcon,
  StyledSearch
} from "./ServicesNavigation.styled";

export const ServicesNavigation = ({ closeMenu }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const location = useLocation();

  const {
    servicesMenuStep,
    isServicesOpened,
    isLocationOpened,
    selectedLocation,
    searchCountry
  } = useSelector(getAllSite);
  const { countries, proxyTypesMenu } = useSelector(getAllContent);

  const {
    setServicesMenuStep,
    setServicesOpened,
    setLocationOpened,
    setSelectedLocation,
    setSearchCountry
  } = useDispatchedActions();

  const handleClick = (step) => {
    setServicesMenuStep(step);
    setLocationOpened(true);
  };

  const isLinkActive = (el) =>
    location?.pathname === `${hrefLang}/${el.route}/` &&
    location?.search === el.search
      ? ACTIVE_STATUS.ACTIVE
      : ACTIVE_STATUS.INACTIVE;

  return (
    <div>
      <NavigationItem
        title="services"
        mobile
        onClick={() => setServicesOpened(!isServicesOpened)}
        isOpened={isServicesOpened}
      >
        <StyledList
          isOpened={isServicesOpened}
          id="services"
          dataTestId="header_mobile_servicesList"
        >
          {proxyServicesList(proxyTypesMenu?.data?.[queryLang])?.map(
            // eslint-disable-next-line array-callback-return
            (el, i) => {
              if (el.title) {
                return (
                  <StyledListItem isOpened={isServicesOpened} key={i}>
                    <StyledListLink
                      onClick={closeMenu}
                      to={
                        el.search
                          ? `${hrefLang}/${el.route}/${el.search}`
                          : `${hrefLang}/${el.route}/`
                      }
                      active={isLinkActive(el)}
                      data-testid={`header_mobile_${camelCase(el?.title)}`}
                    >
                      <StyledListTitle>
                        {el?.title}
                        {el.chip && <Chip label={el.chip} />}
                      </StyledListTitle>
                      {el.description && (
                        <StyledListDescription>
                          {el?.description}
                        </StyledListDescription>
                      )}
                    </StyledListLink>
                  </StyledListItem>
                );
              }
            }
          )}
        </StyledList>
        {servicesMenuStep === "locations" && (
          <LocationsNavigationItem
            title="locations"
            mobile
            onClick={() => setLocationOpened(!isLocationOpened)}
            isOpened={isLocationOpened}
          >
            <StyledSearch
              value={searchCountry}
              onChange={setSearchCountry}
              variant="outlined-dark"
              isOpened={isLocationOpened}
              dataTestId="header_mobile_searchInput"
            />
            {searchCountry ? (
              <Countries
                countries={countries}
                search={searchCountry}
                selectedLocation={selectedLocation}
                dataTestId="header_mobile_countriesList"
              />
            ) : (
              <StyledLocationsList
                selectedLocation={selectedLocation}
                onClick={setSelectedLocation}
                setCurrentStep={handleClick}
                isOpened={isLocationOpened}
                dataTestId="header_mobile_locationsList"
              />
            )}
          </LocationsNavigationItem>
        )}
        {servicesMenuStep === "countries" && (
          <>
            {isLocationOpened && (
              <StyledButton
                onClick={() => setServicesMenuStep("locations")}
                dataTestId="header_mobile_locationsButton"
              >
                <StyledIcon name="arrowAltRight" />
                <span>{t("navigation.locations")}</span>
              </StyledButton>
            )}
            <LocationsNavigationItem
              title={selectedLocation}
              isOpened={isLocationOpened}
              onClick={() => setLocationOpened(!isLocationOpened)}
              mobile
            >
              <Countries
                countries={countries}
                search={searchCountry}
                selectedLocation={selectedLocation}
                dataTestId="header_mobile_countriesList"
              />
            </LocationsNavigationItem>
          </>
        )}
      </NavigationItem>
    </div>
  );
};
