import PT from "prop-types";

import { StyledImage, StyledSubtitle, StyledTitle } from "./NoData.styled";

export const NoData = ({
  title,
  subtitle,
  srcX1,
  srcX2,
  imgMaxWidth = 288
}) => (
  <>
    {title && <StyledTitle>{title}</StyledTitle>}
    {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
    <picture>
      {srcX1 && srcX2 && (
        <source srcSet={`${srcX1} 1x, ${srcX2} 2x`} type="image/png" />
      )}
      <StyledImage
        src={srcX1 || srcX2}
        alt="no data"
        imgMaxWidth={imgMaxWidth}
      />
    </picture>
  </>
);

NoData.propTypes = {
  title: PT.string,
  subtitle: PT.string,
  className: PT.string,
  srcX1: PT.string,
  srcX2: PT.string,
  imgMaxWidth: PT.number
};
