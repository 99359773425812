import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "../../../../../components/forms/FormInput/FormInput";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import {
  BlockCharacteristicsInputPlaceholder,
  BlockCharacteristicsInputWrapper,
  BlockCharacteristicsStyledBody,
  BlockCharacteristicsStyledBodyRow,
  BlockCharacteristicsStyledBodyRowTitle
} from "./BlockCharacteristics.styled";

export const BlockCharacteristics = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const speedFrom = watch("speedFrom");
  const speedTo = watch("speedTo");
  const pingFrom = watch("pingFrom");
  const pingTo = watch("pingTo");

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.characteristics.title")}
      subtitle={t("dashboard.services.addEdit.blocks.characteristics.subtitle")}
    >
      <BlockCharacteristicsStyledBody>
        <div>
          <BlockCharacteristicsStyledBodyRowTitle>
            {t("dashboard.services.addEdit.blocks.characteristics.speed")}
          </BlockCharacteristicsStyledBodyRowTitle>
          <BlockCharacteristicsStyledBodyRow>
            <BlockCharacteristicsInputWrapper>
              <FormInput
                name="speedFrom"
                dataTestId="addEditServiceForm_inputSpeedFrom"
                type="text"
                label={t(
                  "dashboard.services.addEdit.blocks.characteristics.from"
                )}
                placeholder={t(
                  "dashboard.services.addEdit.blocks.characteristics.speedPlaceholder"
                )}
                showError
                showValidated
              />
              {speedFrom?.length ? (
                <BlockCharacteristicsInputPlaceholder
                  length={speedFrom?.length}
                >
                  {t(
                    "dashboard.services.addEdit.blocks.characteristics.speedPlaceholder"
                  )}
                </BlockCharacteristicsInputPlaceholder>
              ) : null}
            </BlockCharacteristicsInputWrapper>
            <BlockCharacteristicsInputWrapper>
              <FormInput
                name="speedTo"
                dataTestId="addEditServiceForm_inputSpeedTo"
                type="text"
                label={t(
                  "dashboard.services.addEdit.blocks.characteristics.to"
                )}
                placeholder={t(
                  "dashboard.services.addEdit.blocks.characteristics.speedPlaceholder"
                )}
                showError
                showValidated
              />
              {speedTo?.length ? (
                <BlockCharacteristicsInputPlaceholder
                  length={speedTo?.length}
                >
                  {t(
                    "dashboard.services.addEdit.blocks.characteristics.speedPlaceholder"
                  )}
                </BlockCharacteristicsInputPlaceholder>
              ) : null}
            </BlockCharacteristicsInputWrapper>
          </BlockCharacteristicsStyledBodyRow>
        </div>
        <div>
          <BlockCharacteristicsStyledBodyRowTitle>
            {t("dashboard.services.addEdit.blocks.characteristics.ping")}
          </BlockCharacteristicsStyledBodyRowTitle>
          <BlockCharacteristicsStyledBodyRow>
            <BlockCharacteristicsInputWrapper>
              <FormInput
                name="pingFrom"
                dataTestId="addEditServiceForm_inputPingFrom"
                type="text"
                label={t(
                  "dashboard.services.addEdit.blocks.characteristics.from"
                )}
                placeholder={t(
                  "dashboard.services.addEdit.blocks.characteristics.pingPlaceholder"
                )}
                showError
                showValidated
              />
              {pingFrom?.length ? (
                <BlockCharacteristicsInputPlaceholder
                  length={pingFrom?.length}
                >
                  {t(
                    "dashboard.services.addEdit.blocks.characteristics.pingPlaceholder"
                  )}
                </BlockCharacteristicsInputPlaceholder>
              ) : null}
            </BlockCharacteristicsInputWrapper>
            <BlockCharacteristicsInputWrapper>
              <FormInput
                name="pingTo"
                dataTestId="addEditServiceForm_inputPingTo"
                type="text"
                label={t(
                  "dashboard.services.addEdit.blocks.characteristics.to"
                )}
                placeholder={t(
                  "dashboard.services.addEdit.blocks.characteristics.pingPlaceholder"
                )}
                showError
                showValidated
              />
              {pingTo?.length ? (
                <BlockCharacteristicsInputPlaceholder
                  length={pingTo?.length}
                >
                  {t(
                    "dashboard.services.addEdit.blocks.characteristics.pingPlaceholder"
                  )}
                </BlockCharacteristicsInputPlaceholder>
              ) : null}
            </BlockCharacteristicsInputWrapper>
          </BlockCharacteristicsStyledBodyRow>
        </div>
      </BlockCharacteristicsStyledBody>
    </FormBlock>
  );
};
