import PT from "prop-types";

import {
  StyledCard,
  StyledDescription,
  StyledImage,
  StyledTitle
} from "./PortScannerSliderItem.styled";

export const PortScannerSliderItem = ({ title, image, description }) => (
  <StyledCard size="small">
    <div>
      {image ? (
        <StyledImage
          src={image}
          loading="lazy"
          alt={title}
          aria-hidden="true"
        />
      ) : null}
    </div>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledCard>
);

PortScannerSliderItem.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};
