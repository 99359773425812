import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Checkbox } from "../../../../../components/ui/Checkbox/Checkbox";
import { Dropdown } from "../../../../../components/ui/Dropdown/Dropdown";
import { Icon } from "../../../../../components/ui/Icon/Icon";
import { Search } from "../../../../../components/ui/Search/Search";
import { useLangUrlDefault } from "../../../../../hooks";
import {
  convertCountryCodeAlpha3ToAlpha2,
  getCountryId
} from "../../../../../utils/helpers";

import {
  CountryName,
  DropdownLabel,
  Flag,
  MenuBodyContainer,
  MenuList,
  MenuListItem
} from "./SearchCountryDropdown.styled";

export const SearchCountryDropdown = ({
  data,
  countriesFilter,
  setCountriesFilter
}) => {
  // **Dispatch
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [searchParams] = useSearchParams();

  const handleSearch = (value) => {
    setSearch(value);
  };

  const countryId = getCountryId(data, searchParams.get("country"))?.id;

  useEffect(() => {
    if (countryId && search) {
      setFilteredData(
        data?.filter((country) => {
          const name = queryLang ? country?.localization[queryLang] : "";
          return (
            name.toLowerCase().includes(search.toLowerCase()) &&
            countryId === country.id
          );
        })
      );
    } else if (countryId) {
      setFilteredData(data?.filter((country) => country.id === countryId));
    } else {
      setFilteredData(
        data?.filter((country) => {
          const name = queryLang ? country?.localization[queryLang] : "";
          return name.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [search, data, queryLang, countryId]);

  useEffect(() => {
    if (countryId) {
      setCountriesFilter([countryId]);
    } else {setCountriesFilter([]);}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  const handleCheckboxClick = (id) => {
    if (countriesFilter.includes(id)) {
      setCountriesFilter(countriesFilter?.filter((item) => item !== id));
    } else {
      setCountriesFilter([...countriesFilter, id]);
    }
  };

  return (
    <Dropdown
      label={
        <DropdownLabel>
          {t("freeProxy.filter.country").toUpperCase()}
          <Icon name="filterAlt" />
        </DropdownLabel>
      }
      menuWidth={375}
      align="start"
      direction="bottom"
      portal={true}
    >
      <Search value={search} onChange={handleSearch} variant="outlined" />
      <MenuBodyContainer>
        {filteredData?.length ? (
          <MenuList>
            {filteredData.map((country) => {
              const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                country?.code
              ).toLowerCase();
              const name = queryLang ? country?.localization[queryLang] : "";
              const checked = countriesFilter.includes(country.id);
              return (
                <MenuListItem
                  key={country?.id}
                  role="button"
                  aria-selected={checked}
                  onClick={() => handleCheckboxClick(country.id)}
                >
                  <Checkbox
                    checked={checked}
                    name={name}
                    label={
                      <>
                        <Flag countryCode={alpha2} />
                        <CountryName>{name}</CountryName>
                      </>
                    }
                  />
                </MenuListItem>
              );
            })}
          </MenuList>
        ) : null}
      </MenuBodyContainer>
    </Dropdown>
  );
};
