export const sortMinPriceColumn = (a, b, topFirst) => {
  if (topFirst) {
    // if (a?.topSite && b?.topSite) return sortMinPriceColumn(a, b, false); // if you want to sort top sites by price
    if (a?.topSite || b?.topSite) {return 0;}
  }

  const paramA = a.currentTypeProp?.minPrice;
  const paramB = b.currentTypeProp?.minPrice;

  if (!paramA || (!paramA && paramA !== 0)) {return -1;}
  if (!paramB || (!paramB && paramB !== 0)) {return 1;}

  return paramA - paramB;
};

export const sortMinRentColumn = (a, b, topFirst) => {
  if (topFirst) {
    // if (a?.topSite && b?.topSite) return sortMinRentColumn(a, b, false); // if you want to sort top sites by rent
    if (a?.topSite || b?.topSite) {return 0;}
  }

  const paramA = a?.currentTypeProp?.minRentPeriod?.periodMilliseconds;
  const paramB = b?.currentTypeProp?.minRentPeriod?.periodMilliseconds;

  if (!paramA) {return -1;}
  if (!paramB) {return 1;}

  return paramA - paramB;
};
