import { SectionHead } from "../../../../../components/common/SectionHead/SectionHead";
import { useSeoBlock } from "../../../../../hooks";
import { parseSeoData } from "../../../../../utils/helpers";
import { capitalizeFirstLetter } from "../../../../../utils/helpers/text.helper";

export const ProxyContentTop = () => {
  const { data } = useSeoBlock(["proxyPageProxyTable"]);

  return (
    <SectionHead
      title={capitalizeFirstLetter(parseSeoData(data?.proxyPageProxyTable?.title, "TEXT"))
      }
      description={parseSeoData(data?.proxyPageProxyTable?.description, "TEXT")}
    />
  );
};
