import { useEffect, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { FormCheckbox } from "../../../../../components/forms/FormCheckbox/FormCheckbox";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";

import {
  FormFilter,
  FormLabel,
  RadioGroup,
  RadioGroupBox,
  RestyledFormSelect
} from "./FreeProxyFilter.styled";

export const FreeProxyFilter = ({
  methods,
  defaultValues,
  countries,
  values,
  setValues,
  cities
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [queryLang] = useLangUrlDefault();

  const { proxyTypes } = useSelector(getAllContent);

  const [cityOptions, setCityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    const options = countries?.map((country) => ({
      value: country.localization.en?.toLowerCase(),
      label: country.localization[queryLang]
    }));
    options?.unshift({
      value: "",
      label: t("freeProxy.filter.allCountries")
    });
    setCountryOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, countries]);

  useEffect(() => {
    if (cities) {
      const options = cities?.map((city) => ({
        value: city.name?.toLowerCase(),
        label: city.name
      }));
      options?.unshift({
        value: "",
        label: t("freeProxy.filter.allCities")
      });
      setCityOptions(options?.filter((option) => option.label));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, cities]);

  useEffect(() => {
    const options =
      proxyTypes.data?.[queryLang]
        ?.filter((item) => item.type !== "FreeProxy")
        .map((proxy) => ({
          value: proxy.type,
          label: proxy.name
        })) || [];
    options?.unshift({
      value: "",
      label: t("freeProxy.filter.allTypes")
    });
    setTypeOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, proxyTypes]);

  useEffect(() => {
    const { country, type, city } = methods.getValues();
    const countryValue =
      countryOptions &&
      (countryOptions?.find(
        (item) =>
          item.value === country?.value ||
          item.value === searchParams.get("country")
      ) ||
        countryOptions[0]);
    const typeValue =
      typeOptions &&
      (typeOptions?.find(
        (item) =>
          item.value === type?.value || item.value === searchParams.get("type")
      ) || {
        value: "",
        label: t("freeProxy.filter.allCities")
      });
    const cityValue =
      (cityOptions &&
        cityOptions?.find(
          (item) =>
            item.value === city?.value ||
            item.value === searchParams.get("city")
        )) ||
      cityOptions[0];
    setValues({
      ...values,
      country: countryValue,
      type: typeValue,
      city: cityValue
    });
    // console.log("FreeProxyFilter.jsx", typeValue);
    methods.setValue("country", countryValue);
    methods.setValue("city", cityValue);
    methods.setValue("type", typeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, countryOptions, typeOptions, cityOptions]);

  const handleChange = (name, option) => {
    methods.setValue(name, option);
    setValues({ ...values, [name]: option });
  };
  return (
    <FormProvider {...methods}>
      <FormFilter noValidate autoComplete="off">
        <RestyledFormSelect
          name="country"
          placeholder={t("freeProxy.filter.selectCountry")}
          defaultValue={defaultValues.country}
          label={<FormLabel>{t("freeProxy.filter.country")}</FormLabel>}
          color="dark"
          options={countryOptions}
          value={values.country}
          onChange={(option) => {
            handleChange("country", option);
          }}
          returnObject
        />
        <RestyledFormSelect
          name="city"
          placeholder={t("freeProxy.filter.selectCity")}
          defaultValue={defaultValues.city}
          label={<FormLabel>{t("freeProxy.filter.city")}</FormLabel>}
          color="dark"
          options={cityOptions}
          value={values.city}
          onChange={(option) => {
            handleChange("city", option);
          }}
          returnObject
        />
        <RestyledFormSelect
          name="type"
          placeholder={t("freeProxy.filter.typeOf")}
          defaultValue={defaultValues.type}
          label={<FormLabel>{t("freeProxy.filter.typeOf")}</FormLabel>}
          color="dark"
          options={typeOptions}
          value={values.type}
          onChange={(option) => {
            handleChange("type", option);
          }}
          returnObject
        />
        <RadioGroup>
          <FormLabel>Google</FormLabel>
          <RadioGroupBox>
            <FormCheckbox
              name="google"
              type="radio"
              label={t("ui.yesNo.yes")}
              radioValue="yes"
              radioClearable
            />
            <FormCheckbox
              name="google"
              type="radio"
              label={t("ui.yesNo.no")}
              radioValue="no"
              radioClearable
            />
          </RadioGroupBox>
        </RadioGroup>
      </FormFilter>
    </FormProvider>
  );
};
