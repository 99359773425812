import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const RestyledMainSection = styled(Main)`
  // padding-bottom: 55px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    // padding-bottom: 210px;
    // min-height: 600px;

    & .main-description {
      width: 70%;
  }

    & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 105px;
      padding-bottom: 308px;
    }
  }


  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
       top: 43px;
      right: 30px;
      width: 406px;
      height: 246px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      right: 20px;
      width: 508px;
      height: 301px;
    }

    @media (min-width: 1350px) {
      right: -15px;
    }

    img {
      margin-top: -20px;
    }
  }
`;

export const StyledSection = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    background-color: ${(p) => p.theme.colors["background-color"]};
  }
`;

export const StyledFAQSection = styled(FAQ)`
  img {
    width: 100%;
    max-width: 409px;
  }
`;
