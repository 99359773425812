import styled from "@emotion/styled";

export const StyledRecentReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 12px;
  box-shadow: ${(p) => p.theme.shadows.recentReviewCard};
  background-color: ${(p) => p.theme.colors.white};
  conlor: ${(p) => p.theme.colors["neutral-800"]};

  font-family: ${(p) => p.theme.fontFamily.lato};
  font-style: normal;
  font-weight: 400;

  // transition: box-shadow 0.3s ease;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    padding: 20px;
  }

  & .recent_site {
    display: none;
  }

  ${(p) =>
    p.isMainPage &&
    `
  &:hover {
    // box-shadow: ${p.theme.shadows.recentReviewCardHover};
    // transition: box-shadow 0.3s ease;

    & .recent_info {
      margin: 0;
      color: transparent;

      transition: color 0.3s ease;

      & > .recent_date {
         opacity: 1;
        transition: opacity 0.3s ease;
     }
    }

  }
    `}
`;

export const StyledRecentReviewHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  line-height: normal;
  margin-bottom: 16px;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    height: ${(p) => (p.isMainPage ? "36px" : "auto")};
  }
`;

export const StyledRecentReviewNameBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: calc(100% - 110px);
`;

export const StyledRecentReviewName = styled.div`
  font-size: ${(p) => p.theme.fontSizes.m};
  line-height: 120%;
  font-weight: 700;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledRecentReviewInfo = styled.div`
  position: relative;
  display: inline-flex;
  font-size: ${(p) => p.theme.fontSizes.xxs};
  line-height: 117%;
  color: ${(p) =>
    p.isMainPage ? p.theme.colors["neutral-400"] : "transparent"};

  transition: color 0.3s ease;
  & .recent_date {
    color: ${(p) => p.theme.colors["neutral-400"]};
    position: absolute;
    top: 0;
    left: 0;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;

    opacity: ${(p) => (p.isMainPage ? 0 : 1)};

    transition: opacity 0.3s ease;
  }
`;

export const StyledRecentReviewStarsBox = styled.div`
  margin-left: auto;
`;

export const StyledRecentReviewText = styled.p`
  font-size: ${(p) => p.theme.fontSizes.s};
  line-height: 136%;
  height: 37px;
  max-height: 37px;
  word-break: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledBoxTextSkeleton = styled.div`
  width: 100%;
`;
