import { useTranslation } from "react-i18next";

import {
  RestyledTitle,
  SuccessStyledBox,
  SuccessStyledImage,
  SuccessStyledText
} from "./Success.styled";

export const Success = () => {
  const { t } = useTranslation();
  return (
    <SuccessStyledBox>
      <RestyledTitle tag="div">
        {t("modals.review.form.success.title")}
      </RestyledTitle>
      <SuccessStyledText>
        {t("modals.review.form.success.text")}
      </SuccessStyledText>
      <picture>
        <source
          srcSet="./img/review/success-1x.png 1x, ./img/review/success-2x.png 2x"
          type="image/png"
        />
        <SuccessStyledImage
          src="./img/review/success-1x.png"
          alt="Thank you"
        />
      </picture>
    </SuccessStyledBox>
  );
};
