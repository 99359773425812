import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { LOCALS } from "../utils/constants/locals";
import translationEN from "./locales/en/translation.js";
import translationRU from "./locales/ru/translation.js";
import translationUA from "./locales/ua/translation.js";

// eslint-disable-next-line import/no-named-as-default-member
i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ua", "ru"],
    fallbackLng: LOCALS.UA,
    debug: false,

    interpolation: {
      escapeValue: false
    },
    // backend: {
    //   loadPath: "/locales/{{lng}}/{{ns}}.json"
    // },
      resources: {
          en: { translation: translationEN },
          ru: { translation: translationRU },
          ua: { translation: translationUA }
      },
      react: {
      useSuspense: false
    }
  });

export default i18n;
