import { useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FormInput } from "../../../../../forms/FormInput/FormInput";
import { Button } from "../../../../../ui/Button/Button";
import { Modal } from "../../../../../ui/Modal/Modal";

import { ModalButtons } from "./UserPart.styled";

export const ModalAppeal = ({ open, close, idComment }) => {
  const { t } = useTranslation();
  const methods = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const cancelHandler = (value) => {
    close?.(value);
  };

  const submitHandler = (data) => {
    setIsSubmitting(true);
    const params = {
      id: idComment,
      text: data.text
    };
    setTimeout(() => {
      // eslint-disable-next-line no-console
      console.log(params);
      setIsSubmitting(false);
      close?.(false);
      toast.success("Your appeal has been sent");
    }, 2000);
  };

  return (
    <Modal
      size="sm"
      open={open}
      setOpen={close}
      title={t("dashboard.reviews.appeal.modal.title")}
      maxWidth={550}
      mobilePosition="bottom"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)} noValidate>
          <FormInput
            name="text"
            type="textarea"
            placeholder={t("forms.comment.placeholder")}
            label={t("forms.comment.label")}
            rows={6}
          />
          <ModalButtons>
            <Button loading={isSubmitting} type="submit">
              {t("forms.buttons.send")}
            </Button>
            <Button
              variant="secondary"
              onClick={() => cancelHandler(false)}
              loading={isSubmitting}
            >
              {t("forms.buttons.cancel")}
            </Button>
          </ModalButtons>{" "}
        </form>
      </FormProvider>
    </Modal>
  );
};
