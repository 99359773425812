import { useState } from "react";

import { useTranslation } from "react-i18next";

import { LeaveReviewModal } from "../../../../../../components/common/Modals/LeaveReviewModal/LeaveReviewModal";
import { Button } from "../../../../../../components/ui/Button/Button";
import { TabTitle } from "../../TabTitle/TabTitle";

import {
  StyledAddReviewContainer,
  StyledAddReviewContant
} from "./AddReview.styled";

export const AddReview = ({ active, captchaRef, setTokenCaptcha }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <StyledAddReviewContainer active={active}>
      <StyledAddReviewContant>
        <TabTitle
          title={t("proxySitePage.about.reviews.title")}
          imgSrc="/img/site-page/Reviews.png"
        />
        <Button size="md" onClick={handleOpen}>
          {t("proxySitePage.about.reviews.btn")}
        </Button>
        <LeaveReviewModal
          visible={open}
          cancelHandler={() => setOpen(false)}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
        />
      </StyledAddReviewContant>
    </StyledAddReviewContainer>
  );
};
