import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useLangUrlDefault, useMatchedRoute } from "../../../hooks";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { StyledSkeleton } from "../../ui/Skeleton/components/SkeletonTableComponents.styled";

import {
  StyledTableLink,
  StyledTableTabText,
  StyledTableTabs
} from "./TableTabs.styled";

export const TableTabs = () => {
  // **Props
  const [queryLang, hrefLang] = useLangUrlDefault();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("fpt") || "IPv4";
  const route = useMatchedRoute();

  // **Redux state
  const { proxyTypes } = useSelector(getAllContent);

  const proxyTypesForLang = proxyTypes.data?.[queryLang];
  const isProxyTypesForLangNotEmpty =
    proxyTypesForLang && proxyTypesForLang?.length !== 0;

  return (
    <StyledTableTabs>
      {proxyTypes?.isDataLoaded ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {isProxyTypesForLangNotEmpty &&
            proxyTypesForLang?.map(({ type, name }) => (
              <StyledTableLink
                key={type}
                variant="secondary"
                className={activeTab === type ? "activeTab" : ""}
                to={`${hrefLang}${
                  route?.tag === "proxy" ? "/proxy" : ""
                }/?fpt=${type}`}
              >
                <StyledTableTabText id={name}>{name}</StyledTableTabText>
              </StyledTableLink>
            ))}
        </>
      ) : (
        Array.from({ length: 5 }).map((_, index) => (
          <StyledSkeleton key={index} width={100} height={40} />
        ))
      )}
    </StyledTableTabs>
  );
};
