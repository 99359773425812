import { useId, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ApiService } from "../../../services";
import { Icon } from "../../ui/Icon/Icon";

import { CropperImg } from "./CropperImg/CropperImg";
import {
  RestyledTextButton,
  TextActionStyled,
  UploaderIconStyled,
  UploaderInputStyled,
  UploaderSubActionBoxStyled,
  UploaderWrapperStyled
} from "./Uploader.styled";

export const Uploader = ({
  defaultImage = null,
  setDefaultValues,
  onUpload,
  croppedFileObjects,
  setCroppedFileObjects,
  className,
  ...rest
}) => {
  const { id } = useParams();
  const inputId = useId();
  const { t } = useTranslation();
  // **Local state
  const [fileObject, setFileObject] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const fileInputRef = useRef(null);

  const dragHandler = (e, state) => {
    e.preventDefault();
    setIsDragging(state);
  };

  const uploadHandler = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e?.dataTransfer?.files[0] || e?.target?.files[0];
    e.target.value = "";

    if (!file) {
      toast.error(t("notifications.file.badFile"));
      return;
    }

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      toast.error(t("notifications.file.badFileType"));
      return;
    }

    if (file.size / 1024 / 1024 >= 2) {
      toast.error(t("notifications.file.largeSize"));
      return;
    }

    setFileObject(file);
    setIsModalOpened(true);
  };

  const modalHandler = () => {
    setIsModalOpened(false);
  };

  const handleDeleteImage = (e) => {
    const element = document.getElementById("delete-image");

    if (
      e.target === element ||
      e.target.parentNode === element ||
      e.target.parentNode.parentNode === element
    ) {
      setCroppedFileObjects([]);
      setFileObject(null);
      e.preventDefault();
      e.stopPropagation();

      if (id) {
        ApiService.deleteImage(id)
          .then((response) => {
            if (response?.response && response?.response?.status !== 200) {
              throw response;
            }

            setDefaultValues((prevState) => ({
              ...prevState,
              image: null,
              imageTop: null
            }));
          })
          .catch(() => {
            toast.error(t("notifications.apiError"));
          });
      }
    }
  };

  const fileSelectHandler = (e) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <UploaderWrapperStyled
        htmlFor={inputId}
        className={className}
        isDragging={isDragging}
        onDragStart={(e) => dragHandler(e, true)}
        onDragOver={(e) => dragHandler(e, true)}
        onDragLeave={(e) => dragHandler(e, false)}
        onDrop={uploadHandler}
        onClick={handleDeleteImage}
        {...rest}
      >
        <UploaderIconStyled>
          {croppedFileObjects?.length || defaultImage ? (
            <img
              src={
                typeof croppedFileObjects[0] === "object"
                  ? URL?.createObjectURL(croppedFileObjects[0])
                  : defaultImage
              }
              loading="lazy"
              alt=""
              aria-hidden="true"
            />
          ) : (
            <Icon name="uploadCloud" />
          )}
        </UploaderIconStyled>

        <UploaderSubActionBoxStyled>
          {croppedFileObjects?.length || defaultImage ? (
            <TextActionStyled>
              <RestyledTextButton
                color="secondary"
                size="sm"
                onClick={fileSelectHandler}
              >
                {t("uploader.chooseOtherFile")}
              </RestyledTextButton>
              {` ${t("uploader.or")} `}
              <RestyledTextButton id="delete-image" color="error" size="sm">
                {t("forms.buttons.delete")}
              </RestyledTextButton>
            </TextActionStyled>
          ) : (
            <TextActionStyled data-text={` ${t("uploader.chooseFileSecond")}`}>
              <RestyledTextButton
                color="secondary"
                size="sm"
                onClick={fileSelectHandler}
              >
                {isDragging
                  ? t("uploader.draggingState")
                  : t("uploader.chooseFile")}
              </RestyledTextButton>
            </TextActionStyled>
          )}
          {t("uploader.format")}
        </UploaderSubActionBoxStyled>
        <UploaderInputStyled
          id={inputId}
          type="file"
          accept="image/jpeg, image/png"
          onChange={uploadHandler}
          ref={fileInputRef}
        />
      </UploaderWrapperStyled>
      <CropperImg
        isModalOpened={isModalOpened}
        defaultImage={defaultImage}
        fileObject={fileObject}
        setCroppedFileObjects={setCroppedFileObjects}
        onUpload={onUpload}
        cancelModalHandler={modalHandler}
      />
    </>
  );
};
