import { useEffect, useState } from "react";

import moment from "moment";

export const AnonimityTime = ({ time }) => {
  const [currentTime, setCurrentTime] = useState(
    time
      ? moment(time).format("YYYY-MM-DD, HH:mm:ss")
      : moment().format("YYYY-MM-DD, HH:mm:ss")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(
        moment(currentTime).add(1, "second").format("YYYY-MM-DD, HH:mm:ss")
      );
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, currentTime]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{currentTime}</>;
};
