import styled from "@emotion/styled";

export const StyledStars = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  height: 20px;
`;

export const StyledStar = styled.div`
  width: ${(p) => (p.size ? p.size : 16)}px;
  height: ${(p) => (p.size ? p.size : 16)}px;
  flex-shrink: 0;

  & svg {
    width: 100%;
    height: 100%;
  }

  color: ${(p) => {
    if (p.value >= 4) {return p.theme.colors["main-500"];}
    if (p.value >= 3) {return p.theme.colors.yellow;}
    return p.theme.colors["red-500"];
  }};

  &.none_color {
    color: ${(p) => p.theme.colors["neutral-50"]};
  }
`;
