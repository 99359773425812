import PT from "prop-types";

import { Icon } from "../Icon/Icon";

import { StyledButton, StyledButtonLink, StyledLoading } from "./Button.styled";

const BUTTON_SIZES = ["lg", "nm", "md", "sm"];
const BUTTON_VARIANTS = [
  "primary",
  "secondary",
  "outlined",
  "danger",
  "tertiary"
];
const BUTTON_TYPES = ["button", "submit", "reset", "icon", "link"];

export const Button = ({
  variant = "primary",
  size = "nm",
  type = "button",
  children,
  onClick,
  ariaLabel,
  fullWidth = false,
  linkTo,
  target,
  disabled = false,
  iconRight,
  iconLeft,
  loading = false,
  className = "",
  dataTestId
}) => {
  if (linkTo || type === "link") {
    return (
      <StyledButtonLink
        to={linkTo}
        className={className}
        onClick={(event) => {
          if (disabled) {event.preventDefault();}
          if (!disabled && onClick) {onClick(event);}
        }}
        aria-label={ariaLabel}
        disabled={disabled || loading}
        {...(dataTestId && { "data-testid": dataTestId })}
        target={target}
        variant={variant}
        size={size}
        full={fullWidth.toString()}
      >
        {!!iconLeft && !loading && <Icon name={iconLeft} />}
        {loading && <StyledLoading name="loading" />}
        {!!children && <div>{children}</div>}
        {!!iconRight && <Icon name={iconRight} />}
      </StyledButtonLink>
    );
  }

  return (
    <StyledButton
      type={type === "icon" ? "button" : type}
      className={className}
      onClick={onClick}
      aria-label={ariaLabel}
      {...(dataTestId && { "data-testid": dataTestId })}
      disabled={disabled || loading}
      variant={variant}
      size={size}
      full={fullWidth.toString()}
    >
      {!!iconLeft && !loading && <Icon name={iconLeft} />}
      {loading && <StyledLoading name="loading" />}
      {!!children && <div>{children}</div>}
      {!!iconRight && <Icon name={iconRight} />}
    </StyledButton>
  );
};

Button.propTypes = {
  className: PT.string,
  variant: PT.oneOf(BUTTON_VARIANTS),
  size: PT.oneOf(BUTTON_SIZES),
  disabled: PT.bool,
  style: PT.shape({}),
  onClick: PT.func,
  type: PT.oneOf(BUTTON_TYPES),
  children: PT.node,
  ariaLabel: PT.string,
  fullWidth: PT.bool,
  linkTo: PT.string,
  target: PT.string,
  iconRight: PT.string,
  iconLeft: PT.string,
  loading: PT.bool,
  dataTestId: PT.string
};
