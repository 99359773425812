import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Search } from "../../../../components/ui/Search/Search";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import {
  convertCountryCodeAlpha3ToAlpha2,
  getParamsUrlForFilter
} from "../../../../utils/helpers";

import {
  CountryLink,
  CountryName,
  LabelCountryFlag,
  ListCountries,
  RestyledModal,
  StyledContainer,
  StyledContinent,
  StyledContinentName,
  StyledCountry
} from "./AllProxyCountriesModal.styled";

export const AllProxyCountriesModal = ({ data, open, onClose }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();
  const [queryLang, hrefLang] = useLangUrlDefault();

  const [search, setSearch] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
  const [currentFilteredData, setCurrentFilteredData] = useState([]);

  const handleSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    const continents = t("continents", { returnObjects: true });
    const countryArray = data?.filter((country) => {
      const name = queryLang ? country?.localization[queryLang] : "";
      return name.toLowerCase().includes(search.toLowerCase());
    });
    if (countryArray?.length) {
      const current = countryArray.reduce((acc, country) => {
        if (!continents[country?.continent]) {
          acc.other = [...(acc?.other || []), country];
          return acc;
        }
        acc[country?.continent] = [...(acc[country?.continent] || []), country];
        return acc;
      }, {});
      const newData = Object.keys(continents).reduce((acc, continent) => {
        if (current[continent]) {
          acc.push({ continent, data: current[continent] });
        }
        return acc;
      }, []);
      if (current.other) {
        newData.push({ continent: "other", data: current.other });
      }
      setCurrentFilteredData(newData);
    } else {
      setCurrentFilteredData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, data, queryLang]);

  return (
    <RestyledModal
      open={open}
      setOpen={onClose}
      maxWidth={770}
      size="lg"
      title={t("proxyPage.content.table.geo.modal.title")}
      mobilePosition="bottom"
    >
      <Search value={search} onChange={handleSearch} variant="outlined" />

      {currentFilteredData?.length ? (
        <StyledContainer>
          {currentFilteredData.map(({ continent, data }) => (
            <StyledContinent key={continent}>
              <StyledContinentName>
                {continent === "other"
                  ? t("proxyPage.content.table.geo.modal.subtitles.other")
                  : t(`continents.${continent}`)}
              </StyledContinentName>
              <ListCountries>
                {data.map((country) => {
                  const newCountry = country?.urlParam;
                  const alpha2 = convertCountryCodeAlpha3ToAlpha2(
                    country?.code
                  ).toLowerCase();
                  const name = queryLang
                    ? country?.localization[queryLang]
                    : "";
                  return (
                    <StyledCountry key={country?.id}>
                      <CountryLink
                        to={`${hrefLang}/proxy/${getParamsUrlForFilter(
                          newCountry,
                          searchParams.get("fg"),
                          ["IPv4"],
                          searchParams.get("s")
                        )}`}
                        onClick={() => setActiveFilters({ fc: newCountry })}
                        title={name}
                      >
                        <LabelCountryFlag countryCode={alpha2} />
                        <CountryName className="country-name">
                          {name}
                        </CountryName>
                      </CountryLink>
                    </StyledCountry>
                  );
                })}
              </ListCountries>
            </StyledContinent>
          ))}
        </StyledContainer>
      ) : null}
    </RestyledModal>
  );
};
