import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AllProxyCountriesModal } from "../../../../../components/common/Modals/AllProxyCountriesModal/AllProxyCountriesModal";
import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllContent } from "../../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getCountriesById, time } from "../../../../../utils/helpers";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledInformadionsBlock,
  StyledListInfo,
  StyledTextButton
} from "./Informations.styled";
import { RowInfo } from "./RowInfo";

export const Info = ({ active }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const {
    site: { data, isDataLoaded }
  } = useSelector(getAllProxySite);

  const [open, setOpen] = useState(false);

  const { countries } = useSelector(getAllContent);

  const newDataCountries =
    data?.proxyTypeProps?.length > 0
      ? data.proxyTypeProps.reduce((acc, item) => {
          if (item.countryIds && item.countryIds.length > 0) {
            return [
              ...acc,
              ...item.countryIds.filter((id) => !acc.includes(id))
            ];
          }
          return acc;
        }, [])
      : [];
  const countriesList = getCountriesById(newDataCountries, countries.data);

  const auth = [
    ...(data?.clientIPAuth
      ? [t("proxyPage.content.table.characteristics.autorization.ip")]
      : []),
    ...(data?.clientLoginAuth
      ? [t("proxyPage.content.table.characteristics.autorization.login")]
      : []),
    ...(data?.clientNoAuth
      ? [t("proxyPage.content.table.characteristics.autorization.noAuth")]
      : [])
  ];

  return (
    <StyledInformadionsBlock active={active}>
      <TabTitle
        title={t("proxySitePage.about.info.title")}
        imgSrc="/img/site-page/Info.png"
      />
      {isDataLoaded ? (
        <StyledListInfo>
          {countriesList?.length ? (
            <RowInfo
              title={t("proxySitePage.about.info.countries")}
              valueDirection="column"
            >
              <span>
                {countriesList?.length} {t("proxyPage.content.table.geo.title")}
              </span>
              <StyledTextButton size="md" onClick={() => setOpen(true)}>
                {t("proxyPage.content.table.geo.modal.title")}
              </StyledTextButton>
            </RowInfo>
          ) : null}
          {(data?.speedFrom || data?.speedFrom === 0) && data?.speedTo ? (
            <RowInfo
              title={t("proxySitePage.about.info.speed")}
              tooltip={t("proxySitePage.about.info.speedTooltip")}
              labelCenter
            >
              <span>{`from ${data?.speedFrom} Mb/s`}</span>
              <span>{`to ${data?.speedTo} Mb/s`}</span>
            </RowInfo>
          ) : null}
          {(data?.pingFrom || data?.pingFrom === 0) && data?.pingTo ? (
            <RowInfo
              title={t("proxySitePage.about.info.ping")}
              tooltip={t("proxySitePage.about.info.pingTooltip")}
              labelCenter
            >
              <span>{`from ${data?.pingFrom} ms`}</span>
              <span>{`to ${data?.pingTo} ms`}</span>
            </RowInfo>
          ) : null}
          <RowInfo title={t("proxySitePage.about.info.auth")} labelCenter>
            {auth?.length
              ? auth.map((item, index) => (
                  <span key={`auth${index}`}>
                    {item}
                    {index + 1 < auth?.length ? "," : ""}
                  </span>
                ))
              : null}
          </RowInfo>
          <RowInfo title={t("proxySitePage.about.info.api")}>
            <YesNo value={data?.api} />
          </RowInfo>
          <RowInfo title={t("proxySitePage.about.info.freeTest")}>
            <YesNo value={data?.freeTest} />
          </RowInfo>
          <RowInfo title={t("proxySitePage.about.info.affiliate")}>
            <YesNo value={data?.affiliateProgram} />
          </RowInfo>
          <RowInfo title={t("proxy.replacementPossibility.label")}>
            <YesNo value={data?.replacementPossibility} />
          </RowInfo>

          <RowInfo title={t("proxySitePage.about.info.refund")}>
            {data?.refunds && data?.refundPeriod > 0 ? (
              <span>{`${time(
                "day",
                data?.refundPeriod,
                queryLang,
                t("times", { returnObjects: true })
              )}`}</span>
            ) : (
              <YesNo value={false} />
            )}
          </RowInfo>
        </StyledListInfo>
      ) : (
        <StyledListInfo>
          {[...Array(4)].map((_, index) => (
            <RowInfo key={`skeleton_${index}`} loading />
          ))}
        </StyledListInfo>
      )}

      <AllProxyCountriesModal
        data={countriesList}
        open={open}
        onClose={setOpen}
      />
    </StyledInformadionsBlock>
  );
};
