import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";

export const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.colors["background-color-seo"]};
`;

export const StyledContainer = styled(Container)`
  padding-top: 120px !important;
  padding-bottom: ${(p) =>
    p.paddingBottom ? "64px !important" : "0 !important"};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: ${(p) =>
      p.paddingBottom ? "120px !important" : "0 !important"};
  }
`;
