import styled from "@emotion/styled";

export const StyledRenderValueBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  width: calc(100% - 40px);
`;

export const StyledValueButton = styled.button`
  display: flex;
  align-items: center;
  padding: 2px 12px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors["neutral-25"]};
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 4px;
    width: 16px;
    height: 16px;
  }
`;

export const StyledDots = styled.span`
  margin-left: auto;
`;

export const StyledInfoMultipleEnd = styled.div`
  padding: 2px 12px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors["background-color"]};
  display: flex;
  align-items: center;
`;
