import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ApiService } from "../../../../services";
import { TraicingIpSchema } from "../../../../utils/validation/traicingIp.validation";

import { CTA } from "./CTA/CTA";
import { StyledContainer, StyledSection } from "./PortScanner.styled";
import { PortScannerSlider } from "./PortScannerSlider/PortScannerSlider";
import { PortScannerSteps } from "./PortScannerSteps/PortScannerSteps";
import { PortsTable } from "./PortsTable/PortsTable";
import { TestPort } from "./TestPort/TestPort";

export const PortScanner = () => {
  const { t } = useTranslation();

  const methods = useForm({
    resolver: yupResolver(
      TraicingIpSchema(t("portScanner", { returnObjects: true }))
    )
  });

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await ApiService.portScan({
        ipOrDomain: data.ipAddress,
        portType: data.type.value,
        scanType: ["TCP", "UDP"]
      });
      if (response && response.status === 200) {
        setResult(response.data);
      }
      if (response && response.status !== 200) {
        toast.error(t("notifications.apiError"));
      }
    } catch (error) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <StyledSection>
      <StyledContainer paddingBottom={result?.ports}>
        <TestPort
          result={result}
          isLoading={isLoading}
          onSubmit={onSubmit}
          methods={methods}
        />
        {result?.ports ? (
          <PortsTable data={result?.ports} isLoading={isLoading} />
        ) : null}
      </StyledContainer>
      <PortScannerSlider />
      <CTA />
      <PortScannerSteps />
    </StyledSection>
  );
};
