import { Link } from "react-router-dom";

import { Promocode } from "../../../../components/common/Promocodes/Promocode";
import { StyledSkeleton } from "../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";
import { useFavicon, useLangUrlDefault } from "../../../../hooks";

import {
  StyledOfferItem,
  StyledOfferItemBox,
  StyledOfferItemSiteImage,
  StyledOfferItemTitle
} from "./OfferItem.styled";

export const OfferItem = ({ item, loading }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  const itemImage = useFavicon(
    item?.favicon,
    item?.faviconContentType,
    item?.imageUrl
  );

  return (
    <StyledOfferItem>
      <StyledOfferItemTitle>
        {loading ? (
          <>
            <StyledSkeleton width={24} height={24} />
            <StyledSkeleton width={100} height={16} />
          </>
        ) : (
          <>
            <StyledOfferItemSiteImage
              src={itemImage}
              loading="lazy"
              alt=""
              aria-hidden="true"
            />
            <Link to={`${hrefLang}/proxy/${item?.siteAlias || item?.siteId}/`}>
              {item?.siteName}
            </Link>
          </>
        )}
      </StyledOfferItemTitle>
      <StyledOfferItemBox>
        <Promocode
          variant="dark"
          description={item?.description?.[queryLang] || ""}
          promocode={item?.promocode}
          title={item?.siteName}
          time={item?.endDate}
          eternal={item?.eternal}
          image={null}
          siteId={item?.siteId}
          loading={loading}
        />
      </StyledOfferItemBox>
    </StyledOfferItem>
  );
};
