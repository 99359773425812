import styled from "@emotion/styled";

export const StyledSeoSection = styled.section`
  background-color: ${(p) => p.theme.colors["background-color-seo"]};
  padding-top: ${(p) => (p.noPaddingTop ? "0" : "64px")};
  padding-bottom: 64px;
  width: 100%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: 120px;
    padding-top: ${(p) => (p.noPaddingTop ? "0" : "120px")};
  }
`;

export const StyledSeoSectionContent = styled.div`
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: normal;
  font-weight: 400;

  p {
    line-height: 2;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      line-height: 2.87;
    }
  }

  h2 {
    font-size: ${(p) => p.theme.fontSizes.xl};
    font-weight: 700;
    line-height: 1.66;
    margin-top: 48px;
    margin-bottom: 48px;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-bottom: 0;
    }
  }

  ul {
    list-style: inside;
    list-style-type: disc;
  }

  li {
    line-height: 2;
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      line-height: 2.87;
    }
    p {
      line-height: 2;
      @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
        line-height: 2.87;
      }
    }
  }

  p:first-of-type {
    line-height: 2;
  }
`;
