import { useEffect, useId, useState } from "react";

import { useFormContext, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FormError } from "../../../../../../../components/forms/FormError/FormError";
import { Icon } from "../../../../../../../components/ui/Icon/Icon";
import { useLangUrlDefault } from "../../../../../../../hooks";
import { getAllContent } from "../../../../../../../store/reducers/ContentReducer/Content.selectors";

import { BodyCountries } from "./BodyCountries";
import {
  Placeholder,
  StyledCountriesButton,
  StyledCountriesButtonContainer,
  StyledCountriesDesctopContainer,
  StyledCountriesModal,
  StyledCountriesWrapper,
  StyledCountryLabel
} from "./Countries.styled";
import { RenderValue } from "./RenderValue";

export const Countries = ({ prefix, type }) => {
  const [queryLang] = useLangUrlDefault();
  const { t } = useTranslation();
  const buttonId = useId();
  const { getValues, clearErrors, setValue } = useFormContext();
  const { errors } = useFormState();

  const { countries } = useSelector(getAllContent);

  const [active, setActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(() => {
    const locations = getValues(`${prefix}locations`);
    return countries.data?.filter((country) => locations?.includes(country.id));
  });

  const handleOpen = () => {
    const width = window.innerWidth;
    if (width <= 992) {
      setOpenModal(!openModal);
    } else {
      if (openModal) {
        setOpenModal(false);
      }
      setOpen(!open);
    }
    if (active) {
      const button = document.getElementById(buttonId);
      button.blur();
    }
    setActive(!active);
  };

  const hadleSetSelected = (value) => {
    setSelected(
      countries.data?.filter((country) => value.includes(country.id))
    );
    clearErrors(`proxy.${type}.locations`);
  };

  const handleClearCountries = (item) => {
    const selectedCountries = selected.filter(
      (country) => country.id !== item.value
    );
    setValue(
      `${prefix}locations`,
      selectedCountries.map((country) => country.id)
    );
    setSelected(selectedCountries);
  };

  const countriesOptions = selected?.map((country) => ({
    label: country.localization?.[queryLang],
    value: country.id,
    code: country.code
  }));

  useEffect(() => {
    const reopen = () => {
      const width = window.innerWidth;
      if (width <= 992 && open) {
        setOpen(false);
        setOpenModal(true);
      } else if (width > 992 && openModal) {
        setOpenModal(false);
        setOpen(true);
      }
    };

    window.addEventListener("resize", reopen);

    return () => {
      window.removeEventListener("resize", reopen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, openModal]);

  useEffect(() => {
    const clickAway = (e) => {
      if (open) {
        const button = document.getElementById(buttonId);
        const container = document.getElementById(
          "countries-desctop-container"
        );
        if (
          container &&
          !container.contains(e.target) &&
          open &&
          !button.contains(e.target)
        ) {
          setActive(false);
          setOpen(false);
        }
      }
    };

    document.addEventListener("click", clickAway);

    return () => {
      document.removeEventListener("click", clickAway);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <StyledCountriesWrapper>
        <StyledCountryLabel
          isActive={active}
          isError={!!errors?.proxy?.[type]?.locations?.message}
        >
          {t("forms.countries.label")}
        </StyledCountryLabel>
        <StyledCountriesButtonContainer>
          <StyledCountriesButton
            id={buttonId}
            type="button"
            isError={errors?.proxy?.[type]?.locations?.message}
            isActive={active || openModal || open}
            onClick={handleOpen}
          >
            {countriesOptions.length ? (
              <RenderValue
                selected={countriesOptions}
                onClerClick={handleClearCountries}
              />
            ) : (
              <Placeholder>{t("forms.countries.placeholder.many")}</Placeholder>
            )}
            <Icon name="arrowDown" className="indicator" />
          </StyledCountriesButton>
          {open && (
            <StyledCountriesDesctopContainer id="countries-desctop-container">
              <BodyCountries prefix={prefix} setSelected={hadleSetSelected} />
            </StyledCountriesDesctopContainer>
          )}
        </StyledCountriesButtonContainer>
        {errors?.proxy?.[type]?.locations?.message && (
          <FormError message={errors?.proxy?.[type]?.locations?.message} />
        )}
        <StyledCountriesModal
          open={openModal}
          setOpen={handleOpen}
          title={t("proxyPage.content.table.geo.modal.title")}
        >
          <BodyCountries prefix={prefix} setSelected={hadleSetSelected} />
        </StyledCountriesModal>
      </StyledCountriesWrapper>
  );
};
