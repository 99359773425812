import { useTranslation } from "react-i18next";

import { FormInput } from "../../../../forms/FormInput/FormInput";

import {
  StyledPersonalInfo,
  StyledPersonalInfoInputBox,
  StyledPersonalInfoTitle
} from "./PersonalInfo.styled";

export const PersonalInfo = () => {
  const { t } = useTranslation();
  return (
    <StyledPersonalInfo>
      <StyledPersonalInfoTitle>
        {t("modals.review.form.personalInfo.title")}
      </StyledPersonalInfoTitle>

      <StyledPersonalInfoInputBox>
        <FormInput
          name="name"
          label={t("modals.review.form.personalInfo.name")}
          type="text"
          showError
        />

        <FormInput
          name="email"
          label={t("modals.review.form.personalInfo.email")}
          type="email"
          showError
        />
      </StyledPersonalInfoInputBox>
    </StyledPersonalInfo>
  );
};
