import PT from "prop-types";

import { NoFlag, StyledFlag } from "./Flag.styled";
import { FLAGS_POSITION } from "./FlagsPosition.constant";

export const Flag = ({ countryCode, className }) => {
  if (
    !countryCode ||
    !FLAGS_POSITION[countryCode?.toLowerCase()] ||
    countryCode.length !== 2
  ) {
    return (
      <NoFlag
        src="/img/flags/default.svg"
        width={20}
        height={16}
        loading="lazy"
        alt="No image"
      />
    );
  }
  return (
    <StyledFlag
      className={className}
      countryCode={countryCode?.toLowerCase()}
    />
  );
};

Flag.propTypes = {
  countryCode: PT.string,
  className: PT.string
};
