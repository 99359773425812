import { useState } from "react";

import PT from "prop-types";
import { Controller, useFormContext } from "react-hook-form";

import { Icon } from "../../ui/Icon/Icon";
import { Tooltip } from "../../ui/Tooltip/Tooltip";

import {
  StyledRLInput,
  StyledRLLabel,
  StyledRLList,
  StyledRLListLabel,
  StyledRLWrapper
} from "./FormStars.styled";

export const FormStars = ({
  name,
  rules,
  label,
  className,
  defaultValue = false,
  tooltipPrefixText,
  ...rest
}) => {
  const { control } = useFormContext();

  const [indexActive, setIndexActive] = useState(0);
  const handleOnMouse = (index) => {
    setIndexActive(index);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <StyledRLWrapper className={className}>
          {label && <StyledRLListLabel>{label}</StyledRLListLabel>}
          <StyledRLList onMouseLeave={() => handleOnMouse(field.value)}>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <StyledRLLabel
                  key={index}
                  htmlFor={name}
                  index={index + 1}
                  indexActive={indexActive || field.value}
                  aria-checked={Number(field.value) >= index + 1}
                  onMouseEnter={() => handleOnMouse(index + 1)}
                >
                  <Tooltip
                    body={`${tooltipPrefixText} ${index + 1}`.trim()}
                    classNameWrapper="box-item-tooltip"
                    delayShow={1000}
                  >
                    <Icon name="starFill" />

                    <StyledRLInput
                      {...field}
                      {...rest}
                      onFocus={() => {
                        handleOnMouse(index + 1);
                      }}
                      onChange={() => {
                        field.onChange(index + 1);
                      }}
                      type="radio"
                      checked={field.value === index + 1}
                      value={index + 1}
                    />
                  </Tooltip>
                </StyledRLLabel>
              ))}
          </StyledRLList>
        </StyledRLWrapper>
      )}
    />
  );
};

FormStars.PT = {
  name: PT.string.isRequired,
  rules: PT.object,
  label: PT.oneOfType([PT.string, PT.node]).isRequired,
  className: PT.string,
  defaultValue: PT.bool,
  tooltipPrefixText: PT.string
};
