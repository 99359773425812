import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";

export const getProxySiteInfo = createAsyncThunk(
  "proxysite/getProxySiteInfo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteInfo(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.status);
    }
  }
);

export const setSiteViewStatistic = createAsyncThunk(
  "proxysite/setSiteViewStatistic",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.setSiteViewStatistic(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return id;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteReviewsAmount = createAsyncThunk(
  "proxysite/getProxySiteReviewsAmount",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteReviewsAmount(id);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteRating = createAsyncThunk(
  "proxysite/getProxySiteRating",
  async ({ siteId, step }, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getProxySiteRating(
        siteId,
        step,
        signal
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        content: response.data,
        key: `${siteId}-${step}`
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteReviews = createAsyncThunk(
  "proxysite/getProxySiteReviews",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteReviews(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        data: response.data,
        params
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteCompaints = createAsyncThunk(
  "proxysite/getProxySiteCompaints",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteCompaints(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAllPromocodesBySite = createAsyncThunk(
  "proxysite/getAllPromocodesBySite",
  async (siteId, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAllPromocodesBySite(siteId);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySites = createAsyncThunk(
  "proxysite/getProxySites",
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await ApiService.getProxySites(params, signal);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        ...response.data,
        skip: params.page * params.size,
        params
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAlternativeSites = createAsyncThunk(
  "proxysite/getAlternativeSites",
  async (size, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAlternativeSites(size);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getAlternativePromocodes = createAsyncThunk(
  "proxysite/getAlternativePromocodes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getAlternativePromocodes(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const getProxySiteRecentReviews = createAsyncThunk(
  "proxysite/getProxySiteRecentReviews",
  async (params, { rejectWithValue }) => {
    try {
      const response = await ApiService.getProxySiteReviews(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return {
        data: response.data,
        params
      };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setReviewInfo = createAction("proxysite/setReviewInfo");
export const setProxySiteInfo = createAction("proxysite/setProxySiteInfo");
export const setProxySiteReviewsAmount = createAction(
  "proxysite/setProxySiteReviewsAmount"
);
export const setProxySiteRating = createAction("proxysite/setProxySiteRating");
export const setAllPromocodesBySite = createAction(
  "proxysite/setAllPromocodesBySite"
);
export const setProxySiteReviews = createAction(
  "proxysite/setProxySiteReviews"
);
export const setProxySites = createAction("proxysite/setProxySites");
export const setProxySitesFetchParams = createAction(
  "proxysite/setProxySitesFetchParams"
);
export const setProxySitesType = createAction("proxysite/setProxySitesType");
export const clearProxySiteReviews = createAction(
  "proxysite/clearProxySiteReviews"
);
export const setAlternativeSites = createAction(
  "proxysite/setAlternativeSites"
);
export const setAlternativePromocodes = createAction(
  "proxysite/setAlternativePromocodes"
);
export const setProxySiteRecentReviews = createAction(
  "proxysite/setProxySiteRecentReviews"
);
