import styled from "@emotion/styled";

import { FAQ } from "../../../components/common/FAQ/FAQ";
import { Main } from "../../../components/common/Main/Main";

export const RestyledMainSection = styled(Main)`
  // padding-bottom: 60px;
  // @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
  //   padding-bottom: 238px;
  //   min-height: 538px;
  // }

  // @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
  //   padding-bottom: 180px;
  //   min-height: 580px;
  // }

  & .main-content-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-top: 105px;
      padding-bottom: 309px;
    }
  }

  & .main-image-wrapper {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      top: 30px;
      width: 452px;
      height: 260px;
    }

    @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
      width: 565px;
      height: 325px;
    }

    @media (min-width: 1350px) {
      right: -80px;
    }

    &::before {
      bottom: 42px;
    }
  }
`;

export const StyledFAQSection = styled(FAQ)`
  img {
    width: 329px;
  }
`;
