import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SubscribePromoModal } from "../../../../../components/common/Modals/SubscribePromoModal/SubscribePromoModal";
import { Promocode } from "../../../../../components/common/Promocodes/Promocode";
import { Pagination } from "../../../../../components/ui/Pagination/Pagination";
import { TextButton } from "../../../../../components/ui/TextButton/TextButton";
import { useLangUrlDefault } from "../../../../../hooks";
import { getAllProxySite } from "../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getAllUser } from "../../../../../store/reducers/UserReducer/User.selectors";
import { TabTitle } from "../TabTitle/TabTitle";

import {
  StyledImage,
  StyledNoData,
  StyledNoDataSubtitle,
  StyledNoDataTitle,
  StyledPaginationBox,
  StyledPromocodesBlock,
  StyledPromocodesBlockHead,
  StyledPromocodesList,
  StyledShowMore
} from "./Promocodes.styled";

const DEFAULT_PAGE_SIZE = 5;

export const Promocodes = ({ active }) => {
  // **Props
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { isUserAuthenticated } = useSelector(getAllUser);

  // **Redux state
  const { site } = useSelector(getAllProxySite);
  const { promocodes, alternativePromocodes } = useSelector(getAllProxySite);
  const [isAuth, setIsAuth] = useState(false);
  const getCurrentPromocodes = () => {
    if (promocodes.data?.length > 0) {
      return promocodes.data?.filter(
        (item) =>
          Object.keys(item.showIn)?.length === 0 ||
          (Object.keys(item.showIn)?.length > 0 && item.showIn[queryLang])
      );
    }
    return [];
  };

  const getPromoToShow = (params, promocodes) => {
    const from = params.size * (params.page - 1);
    const to = from + params.size * (params.showMore + 1);
    return promocodes.slice(from, to);
  };

  const [showNoData, setShowNoData] = useState(null);
  const [currentPromocodes, setCurrentPromocodes] = useState(
    getCurrentPromocodes()
  );
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    size: DEFAULT_PAGE_SIZE,
    showMore: 0
  });

  const [showedPromocodes, setShowedPromocodes] = useState(() => {
    if (!promocodes.isLoading && promocodes.isDataLoaded) {
      if (currentPromocodes?.length > 0) {
        return getPromoToShow(params, currentPromocodes);
      } else {
        return [];
      }
    }
    return [];
  });
  const triggerClose = () => setOpen((prev) => !prev);

  const handlePageChange = (page) => {
    setParams({ ...params, page, showMore: 0 });

    containerRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  const handleSizeChange = () => {
    setParams({ ...params, showMore: params.showMore + 1 });
  };
  // const promocodesList = currentPromocodes();
  useEffect(() => {
    if (!promocodes.isLoading && promocodes.isDataLoaded) {
      if (currentPromocodes?.length > 0) {
        setShowedPromocodes(getPromoToShow(params, currentPromocodes));
        setShowNoData(false);
      } else {
        setShowedPromocodes([]);
        setShowNoData(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.page, params?.showMore, currentPromocodes]);

  useEffect(() => {
    setParams({ page: 1, size: DEFAULT_PAGE_SIZE, showMore: 0 });
    setCurrentPromocodes(getCurrentPromocodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLang, promocodes.data]);

  useEffect(() => {
    if (isUserAuthenticated) {
      setIsAuth(true);
    }
  }, [isUserAuthenticated]);
  return (
    <StyledPromocodesBlock
      active={active}
      ref={containerRef}
      noData={showNoData}
    >
      <StyledPromocodesBlockHead>
        <TabTitle
          imgSrc="/img/site-page/Promocodes.png"
          title={t("proxySitePage.about.promocodes.title")}
        />
        {isAuth ? (
          <TextButton
            color="secondary"
            iconLeft="mail"
            onClick={triggerClose}
          >
            {t("proxySitePage.about.promocodes.subscribe")}
          </TextButton>
        ) : null}
        <SubscribePromoModal
          open={open}
          onClose={triggerClose}
          siteId={site?.data?.id}
        />
      </StyledPromocodesBlockHead>
      {showedPromocodes?.length > 0 ? (
        <>
          <StyledPromocodesList>
            {showedPromocodes?.map((promocode) => (
              <li key={promocode.id}>
                <Promocode
                  variant="light"
                  promocode={promocode?.promocode}
                  description={promocode?.description?.[queryLang]}
                  title={promocode?.siteName}
                  image={promocode?.imageUrl}
                  time={promocode?.endTime}
                  eternal={promocode?.eternal}
                  siteId={site?.data?.id}
                  favicon={promocode?.favicon}
                  faviconContentType={promocode?.faviconContentType}
                />
              </li>
            ))}
          </StyledPromocodesList>
          {currentPromocodes?.length > 0 &&
            currentPromocodes?.length > params.size && (
              <StyledPaginationBox>
                {currentPromocodes?.length >
                  params.size * (params.page + params.showMore) && (
                  <StyledShowMore
                    onClick={handleSizeChange}
                    iconRight="refresh"
                  >
                    {t("reviews.showMore")}
                  </StyledShowMore>
                )}
                <Pagination
                  rowCount={currentPromocodes?.length}
                  rowsPerPage={params.size}
                  currentPage={params.page + params.showMore}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={1}
                  onChangePage={handlePageChange}
                />
              </StyledPaginationBox>
            )}
        </>
      ) : null}
      {showNoData ? (
        <>
          <StyledNoData>
            <StyledNoDataTitle>
              {t("proxySitePage.content.promocodes.noData.title" , { value: site?.data?.title })}
            </StyledNoDataTitle>
            <StyledNoDataSubtitle>
              {t("proxySitePage.content.promocodes.noData.description")}
            </StyledNoDataSubtitle>
            <picture>
              <source
                srcSet="/img/services/no-promocodes-x1.png 1x, /img/services/no-promocodes-x2.png 2x"
                type="image/png"
              />
              <StyledImage
                src="/img/services/no-promocodes-x1.png"
                alt="no data"
              />
            </picture>
          </StyledNoData>
          {alternativePromocodes.data?.length ? (
            <div>
              <StyledNoDataTitle alternative>
                {t("proxySitePage.content.promocodes.noData.titleAlternative")}
              </StyledNoDataTitle>
              <StyledPromocodesList>
                {alternativePromocodes.data?.map((promocode) => (
                  <li key={promocode.id}>
                    <Promocode
                      variant="light"
                      promocode={promocode?.promocode}
                      description={promocode?.description?.[queryLang]}
                      title={promocode?.siteName}
                      image={promocode?.imageUrl}
                      time={promocode?.endTime}
                      eternal={promocode?.eternal}
                      siteId={site?.data?.id}
                      favicon={promocode?.favicon}
                      faviconContentType={promocode?.faviconContentType}
                    />
                  </li>
                ))}
              </StyledPromocodesList>
            </div>
          ) : null}
        </>
      ) : null}
      {!promocodes.isDataLoaded && (
        <StyledPromocodesList>
          {[...Array(2)].map((_, index) => (
            <li key={index}>
              <Promocode loading variant="light" />
            </li>
          ))}
        </StyledPromocodesList>
      )}
    </StyledPromocodesBlock>
  );
};
