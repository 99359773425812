import { useEffect, useId, useState } from "react";

import "moment/locale/ru";
import "moment/locale/uk";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

import { useLangUrlDefault } from "../../../../../hooks";
import { getCountryByIso } from "../../../../../utils/helpers/getCountryByIso.helper";
import { SearchCountryDropdown } from "../../FreeProxyList/SearchCountryDropdown/SearchCountryDropdown";
import {
  StyledAnonymity,
  StyledAnonymityCol,
  StyledAnonymityWrapper,
  StyledCountryCol,
  StyledFlag,
  StyledIcon,
  StyledIpAddressCol,
  StyledNoData,
  StyledSpeed,
  StyledSpeedNumber,
  StyledTable,
  TableBody,
  TableCol,
  TableHeadCol,
  TableHeadRow,
  TableRow,
  TableText,
  TableWrapper
} from "../DataTable.styled";

import { RestyledTooltip } from "./FreeProxyTable.styled";

export const FreeProxyTable = ({
  isLoading,
  tableData,
  countries,
  countriesFilter,
  setCountriesFilter
}) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const tooltipId = useId();

  const [data, setData] = useState(tableData);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setData(tableData);
  }, [tableData, queryLang]);

  return (
    <>
      <StyledTable id="freeProxyTable">
        <TableWrapper>
          <TableHeadRow>
            <TableHeadCol width="184px" desktopWidth="16%">
              {t("freeProxy.table.ipAddress")}
            </TableHeadCol>
            <TableHeadCol width="95px" desktopWidth="10%">
              {t("freeProxy.table.port")}
            </TableHeadCol>
            <TableHeadCol width="160px" desktopWidth="15%">
              <SearchCountryDropdown
                data={countries}
                countriesFilter={countriesFilter}
                setCountriesFilter={setCountriesFilter}
              />
            </TableHeadCol>
            <TableHeadCol width="140px" desktopWidth="13%">
              {t("freeProxy.filter.city")}
            </TableHeadCol>
            <TableHeadCol width="120px" desktopWidth="11%">
              {t("freeProxy.filter.typeOf")}
            </TableHeadCol>
            <TableHeadCol width="140px" desktopWidth="14%">
              {t("freeProxy.table.anonymity")}
            </TableHeadCol>
            <TableHeadCol width="80px" desktopWidth="8%">
              Google
            </TableHeadCol>
            <TableHeadCol width="135px" desktopWidth="13%">
              {t("freeProxy.table.lastChecked")}
            </TableHeadCol>
          </TableHeadRow>
          <TableBody>
            {!isLoading && data?.length === 0 ? (
              <StyledNoData>
                {t("proxyPage.content.table.noDataComponent")}
              </StyledNoData>
            ) : (
              data.map((item, index) => {
                const alpha2 = item?.countryIsoCode?.toLowerCase();
                const country = getCountryByIso(
                  item?.countryIsoCode,
                  countries
                );
                return (
                  <TableRow key={index}>
                    <TableCol width="184px" desktopWidth="16%">
                      <StyledIpAddressCol>
                        <TableText>{item?.ip}</TableText>
                        <CopyToClipboard text={item?.ip}>
                          <div
                            data-tooltip-id={tooltipId}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => setShowTooltip(true)}
                          >
                            <StyledIcon name="copyAlt" size="lg" />
                          </div>
                        </CopyToClipboard>
                      </StyledIpAddressCol>
                    </TableCol>
                    <TableCol width="95px" desktopWidth="10%">
                      <TableText>{item?.port}</TableText>
                    </TableCol>
                    <TableCol width="160px" desktopWidth="15%">
                      <StyledCountryCol>
                        {alpha2 ? <StyledFlag countryCode={alpha2} /> : ""}
                        <TableText>
                          {country
                            ? country.localization[queryLang]
                            : item?.country}
                        </TableText>
                      </StyledCountryCol>
                    </TableCol>
                    <TableCol width="140px" desktopWidth="14%">
                      <TableText width="100%" desktopWidth="11%">
                        {item?.city}
                      </TableText>
                    </TableCol>
                    <TableCol width="120px" desktopWidth="11%">
                      <TableText>{item?.proxyType}</TableText>
                    </TableCol>
                    <TableCol width="140px" desktopWidth="14%">
                      <StyledAnonymityCol>
                        <StyledAnonymity>
                          {item?.anonymity?.toLowerCase()}
                        </StyledAnonymity>
                        <StyledAnonymityWrapper>
                          <StyledSpeed>Speed</StyledSpeed>
                          <StyledSpeedNumber>
                            {`${item?.speedBytepS?.downloadSpeedBytepS} ms`}
                          </StyledSpeedNumber>
                        </StyledAnonymityWrapper>
                      </StyledAnonymityCol>
                    </TableCol>
                    <TableCol width="80px" desktopWidth="8%">
                      <TableText>
                        {item?.checkedSites?.google ? (
                          <img src="img/icons/check-fill.svg" />
                        ) : (
                          <img src="img/icons/dell-fill.svg" />
                        )}
                      </TableText>
                    </TableCol>
                    <TableCol width="135px" desktopWidth="13%">
                      <TableText width="100%">
                        <Moment
                          fromNow
                          locale={queryLang === "ua" ? "uk" : queryLang}
                          suppressHydrationWarning
                        >
                          {item?.lastChecked}
                        </Moment>
                      </TableText>
                    </TableCol>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </TableWrapper>
      </StyledTable>
      <RestyledTooltip
        body={t("notifications.copied")}
        id={tooltipId}
        place="top-start"
        isOpen={showTooltip}
        openOnClick
      />
    </>
  );
};
