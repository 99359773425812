import styled from "@emotion/styled";

import { Container } from "../../../../../components/common/Container/Container";
import { Rive } from "../../../../../components/ui/Rive/Rive";

export const StyledSection = styled.div`
  background-color: white;
`;

export const StyledContainer = styled(Container)`
  padding-top: 64px !important;
  padding-bottom: 64px !important;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 120px !important;
    padding-bottom: 120px !important;

    & .port-scanner-steps {
      width: 50%;
    }
  }
`;

export const StyledAnimation = styled(Rive)`
  position: absolute;
  top: 70px;
  right: -79px;
  width: 683px !important;
  height: 390px !important;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    & > canvas {
      width: 0;
      height: 0;
    }
  }
`;

export const StyledAnimationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    width: 621px;
    height: 422px;
  }
`;
