import styled from "@emotion/styled";

import { TextButton } from "../../../../ui/TextButton/TextButton";

export const StyledReviewItem = styled.div`
  padding: 16px;
  border-radius: 12px;
  margin-bottom: ${(p) => (p.isRootReview && !p.last ? "16px" : "0")};
  width: 100%;

  background-color: ${(p) =>
    p.nestingLevel % 2 === 0
      ? p.theme.colors["background-color"]
      : p.theme.colors.white};

  box-shadow: ${(p) =>
    !p.isDashboard && p.isRootReview
      ? "0px 15px 46px 0px rgba(135, 140, 189, 0.08)"
      : "none"};
  border: ${(p) =>
    p.isDashboard && p.isRootReview
      ? "1px solid rgba(216, 218, 224, 0.55)"
      : "none"};

  @media (min-width: ${(p) =>
      p.isDashboard ? p.theme.screenSizes.lgMin : p.theme.screenSizes.xlMin}) {
    padding: 24px;
  }
`;

export const StyledAnswers = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 16px;
  margin-top: ${(p) => (p.maxHeight ? "16px" : "0")};

  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "0")};
  visibility: ${(p) => (p.maxHeight ? "visible" : "hidden")};
  overflow: hidden;

  transition: max-height 0.3s ease, margin-top 0.3s ease, visibility 0.3s ease;

  ${(p) =>
    p.nestingLevel <= 3
      ? `
        border-left: 1px solid #ebecef;
        padding-left: 12px;
        `
      : ""}

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-top: ${(p) => (p.maxHeight ? "24px" : "0")};
    ${(p) =>
      p.nestingLevel <= 3
        ? `
        padding-left: 24px;
        `
        : ""}
    transition: max-height 0.3s ease, margin-top 0.3s ease, visibility 0.3s ease;
  }
`;

export const StyledShowAllButton = styled(TextButton)`
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 170%;
  color: ${(p) => p.theme.colors["neutral-800"]};

  &:hover,
  &:focus {
    font-weight: ${(p) => p.theme.fontWeight.normal};
    -webkit-text-stroke: 0.4px;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;
