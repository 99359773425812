import { useTranslation } from "react-i18next";

import { Separator } from "../../../components/ui/Separator/Separator";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { EmailBlock } from "./EmailBlock/EmailBlock";
import { PasswordBlock } from "./PasswordBlock/PasswordBlock";
import { StyledProfileCard } from "./Profile.styled";

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <TitleBlock title={t("dashboard.profile.title")} />
      <StyledProfileCard radius="small">
        <EmailBlock />
        <Separator />
        <PasswordBlock />
      </StyledProfileCard>
    </>
  );
};

export default Profile;
