export const makeWordHighlight = (title, highlightWord) => {
  const currentTitle = title?.trim() || "";
  if (!currentTitle) {
    return;
  }
  const word = highlightWord?.trim() || "";

  return word
    ? currentTitle.replace(word, `<span>${word}</span>`)
    : currentTitle;
};

export const getHighlightedWord = (highlightedWord) =>
  highlightedWord?.replace("проксі", "").replace("прокси", "").trim();

export const capitalizeFirstLetter = (str) => {
  if ((str && str.includes("IPv4")) || str.includes("IPv6") || str.includes("ISP")) {
    return str;
  } else {
    return str[0]?.toUpperCase() + str?.slice(1)?.toLowerCase();
  }
};
