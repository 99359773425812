import { useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../components/common/Progress/Progress";
import { FormInput } from "../../../../components/forms/FormInput/FormInput";
import { useUserIp } from "../../../../hooks/useUserIp";
import { ApiService } from "../../../../services";
import { IpBlacklistCheckSchema } from "../../../../utils/validation/IpBlacklistCheck.validation";

import {
  StyledAdditionalText,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledInfoMessage,
  StyledInnerWrapper,
  StyledInputWrapper,
  StyledList,
  StyledListWrapper,
  StyledResults,
  StyledResultsContent,
  StyledResultsItem,
  StyledTitle
} from "./IpCheck.styled";
import { IpCheckItem } from "./IpCheckItem/IpCheckItem";

export const IpCheck = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      IpBlacklistCheckSchema(t("ipBlacklist", { returnObjects: true }))
    )
  });

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await ApiService.getIpBlacklist(data.ipAddress);
      if (response && response.status === 200) {
        setResult(response.data);
      }
      if (response && response.status !== 200) {
        toast.error(t("notifications.apiError"));
      }
    } catch (error) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const resultArray = useMemo(
    () => (result ? Object.values(result) : []),
    [result]
  );

  const checkList = useMemo(
    () => [
      {
        title: t("ipBlacklist.checks"),
        image: "img/ui/cheks.svg",
        number: resultArray?.length
      },
      {
        title: t("ipBlacklist.checkedOut"),
        image: "img/ui/checkedOut.svg",
        number: resultArray?.filter((el) => !el)?.length
      },
      {
        title: t("ipBlacklist.blacklisted"),
        image: "img/ui/blacklisted.svg",
        number: resultArray?.filter((el) => el)?.length
      }
    ],
    [t, resultArray]
  );

  const memoResultList = useMemo(
    () =>
      result
        ? Object.entries(result)?.map(([key, value], index) => (
            <StyledResultsItem
              key={index}
              variant={value ? "listed" : "notListed"}
            >
              {index + 1}. {key} -{" "}
              <span>
                {value ? t("ipBlacklist.listed") : t("ipBlacklist.notListed")}
              </span>
            </StyledResultsItem>
          ))
        : [],
    [result, t]
  );

  const memoCheckList = useMemo(
    () => checkList.map((item) => <IpCheckItem key={item.title} {...item} />),
    [checkList]
  );

  return (
    <StyledContainer>
      <StyledInnerWrapper>
        <StyledForm>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <StyledTitle tag="h2">
                {t("ipBlacklist.ipCheckserver")}
              </StyledTitle>
              <StyledInputWrapper>
                <FormInput
                  name="ipAddress"
                  type="text"
                  placeholder={t("ipBlacklist.form.enterIp")}
                  label={t("ipBlacklist.form.label")}
                  showError
                  showClear
                  disabled={isLoading}
                />
                <StyledAdditionalText
                  type="button"
                  onClick={() => {
                    methods.setValue("ipAddress", userIp?.ipAddress);
                    methods.clearErrors();
                  }}
                >
                  {t("portScanner.form.pasteIP")}
                </StyledAdditionalText>
              </StyledInputWrapper>
              <StyledButton
                type="submit"
                loading={isLoading}
                {...(isLoading && { iconLeft: "loading" })}
                fullWidth
              >
                {isLoading
                  ? t("ipBlacklist.form.loading")
                  : t("ipBlacklist.form.examination")}
              </StyledButton>
            </form>
          </FormProvider>
          <StyledInfoMessage>
            <img src="img/icons/information.svg" alt="Info icon" />
            <span>{t("ipBlacklist.form.infoMessage")}</span>
          </StyledInfoMessage>
        </StyledForm>
        <StyledListWrapper>
          <StyledList>{memoCheckList}</StyledList>
          <StyledResults isLoading={isLoading}>
            {isLoading ? (
              <Progress isLoading />
            ) : (
              <StyledResultsContent>
                {result ? memoResultList : null}
              </StyledResultsContent>
            )}
          </StyledResults>
        </StyledListWrapper>
      </StyledInnerWrapper>
    </StyledContainer>
  );
};
